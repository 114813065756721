import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import type { SessionCard } from "../../shared/types";
import { useNavigate } from 'react-router-dom';
import { cutoffText } from "../../shared/utils";
import { GAME_MODE } from "../../shared/constants";

type InputProps = {
  card: SessionCard;
  isClickable?: boolean;
};

/**
 * GameCard
 * Component for displaying a game card
 * @param card - The game card to display
 * @param isClickable - Whether the card is clickable
 */
const GameCard = ({ card, isClickable = true }: InputProps) => {
  const [minWidth, setMinWidth] = useState<number>(window.innerWidth <= 500 ? 100 : 200);
  const navigate = useNavigate();

  useEffect(() => {
    window.addEventListener("resize", () => {
      setMinWidth(window.innerWidth <= 500 ? 100 : 200);
    });
  }, []);
  
  /**
   * On click handler for the game card
   */
  const onClick = () => {
    if (!isClickable) return;
    if (!card.videoUrl) return;
    navigate(`/session/${card.sessionId}`);
  }

  /**
   * Get the title of the game card
   */
  const getTitle = () => {
    let title: string = "";
    if (card.sessionMode === GAME_MODE) {
      if (!card.teamNames || card.teamNames.length !== 2) {
        title = `${card.sessionType} game`;
      } else {
        const team1Name = cutoffText(card.teamNames[0], 8);
        const team2Name = cutoffText(card.teamNames[1], 8);
        title = `${team1Name} x ${team2Name}`;
      }
    } else {
      title = "Practice";
    }
    return (
      <Box>
        <Typography sx={{ color: "white", textAlign: "center", fontSize: 14, fontWeight: "bold" }}>
          {title}
        </Typography>
      </Box>
    );
  }

  /**
   * Get the location of the game card
   */
  const getLocation = () => {
    if (!card.city || !card.state) return;
    const text = `${card.city}, ${card.state}`;
    return (
      <Typography sx={{ color: "white", textAlign: "center", fontSize: 12 }}>
        {text}
      </Typography>
    );
  }

  /**
   * Get the timestamp of the game card
   */
  const getTimestamp = () => {
    const date = new Date(card.createdAt * 1000).toLocaleString('en-US', {
      month: '2-digit',
      day: '2-digit',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });
    return (
      <Typography sx={{ color: "white", textAlign: "center", fontSize: 12 }}>
        {date}
      </Typography>
    );
  }

  return (
    <Box onClick={onClick} sx={{ cursor: isClickable ? "pointer" : "default" }}>
      <Box 
        sx={{ 
          width: "100%",
          minWidth: minWidth,
          height: 200,
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7)), url(${card.preview})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: 1,
          filter: isClickable ? 'none' : 'grayscale(100%)',
          transition: 'transform 0.3s ease, filter 0.3s ease', // Smooth transition for hover effects
          '&:hover': {
            transform: 'scale(1.02)', // Zoom effect
          },
        }}
      >
        {getTitle()}
        {getLocation()}
        {getTimestamp()}
      </Box>
    </Box>
  )
};

export default GameCard;
