import React from "react";
import Alert from "@mui/material/Alert";
import { Typography, useMediaQuery, useTheme } from "@mui/material";
/**
 * Web warning component
 * @returns (JSX.Element)
 */
const WebWarning = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Alert severity="warning">
      <Typography sx={{ fontSize: isSmallScreen ? 16 : 18, textAlign: "left" }}>
        The Hooper web client is currently in beta and has limited functionality. For the best experience, please use the mobile app.
      </Typography>
    </Alert>
  );
}

export default WebWarning;
