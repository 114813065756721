import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "./AuthProvider";
import LoadingOverlay from "./LoadingOverlay";

type InputProps = {
  children: React.ReactNode;
}

/**
 * ProtectedRoute
 * This blocks access to the route if the user is not authenticated 
 * and redirects to the redirectPath e.g. `/login`.
 * @param children - The component to render if the user is authenticated.
 */
const ProtectedRoute = ({ children }: InputProps) => {
  const { user, loading }  = useAuth();
  if (loading) return <LoadingOverlay />;
  if (!user) {
    return <Navigate to="/login" replace />;
  } 
  if (!user.username) {
    return <Navigate to="/username" replace />;
  }
  return <>{children}</>;
};

export default ProtectedRoute;
