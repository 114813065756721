import React from "react";
import { Box, Typography } from "@mui/material";
import type { ShotChartRegions } from "../../../shared/types";

type InputProps = {
  shotChartRegions: ShotChartRegions,
}

/**
 * @param shotChartRegions ShotChartRegions
 */
const ShotChartRegionMap = ({ shotChartRegions }: InputProps) => {
  
  const getRegionColor = (percentageString: string, attempts: number) => {
    // Lightest Green #1CBE4940 100%
    // Light Green #1CBE4940 75%
    // Middle Green #1CBE4940 50%
    // Dark Green: #1CBE4940 33%
    // Darkest Green: #1CBE4940 25%
    // Red: #E52E4D33 20%
    // Lightest Green: 76-100
    // Light Green: 50-75
    // Middle Green: 34-49
    // Dark Green: 25-33 **NOT GOING TO DO**
    // Darkest Green: 10-24 **NOT GOING TO DO**
    // Red: 0-9
    if (attempts === 0) return {color: "#202429", opacity: 1};
    const percentage = Number(percentageString.replace("%", ""));
    if (percentage < 10) {
      return {color: "#E52E4D", opacity: 0.7};
    } else if (percentage >= 10 && percentage < 34) {
      return {color: "#1CBE49", opacity: 0.1};
    } else if (percentage >= 34 && percentage < 50) {
      return {color: "#1CBE49", opacity: 0.4};
    } else if (percentage >= 50 && percentage <= 75) {
      return {color: "#1CBE49", opacity: 0.6};
    } else if (percentage > 75) {
      return {color: "#1CBE49", opacity: 1};
    } else {
      return {color: "#202429", opacity: 1};
    }
  }

  const getRegionLabels = () => {
    return (
      <Box style={{ position: 'absolute', width: '100%', height: '100%'  }}>
        {/* paint */}
        <Box sx={{ position: "absolute", top: 50, left: '47%' }}>
          <Typography sx={{ fontSize: 14, fontWeight: "bold", textAlign: "center", color: 'white' }}>
            {shotChartRegions.PAINT.attempts > 0 ? `${shotChartRegions.PAINT.percentage}` : "0%"}
          </Typography>
          <Typography sx={{ fontSize: 12, textAlign: "center", color: 'white' }}>
            {shotChartRegions.PAINT.attempts > 0 ? `${shotChartRegions.PAINT.makes}/${shotChartRegions.PAINT.attempts}` : "0/0"}
          </Typography>
        </Box>
        {/* two point */}
        <Box sx={{ position: "absolute", top: 134, left: "47%" }}>
          <Typography sx={{ fontSize: 14, fontWeight: "bold", textAlign: "center", color: 'white' }}>
            {shotChartRegions.TWO_POINT.attempts > 0 ? `${shotChartRegions.TWO_POINT.percentage}` : "0%"}
          </Typography>
          <Typography sx={{ fontSize: 12, textAlign: "center", color: 'white' }}>
            {shotChartRegions.TWO_POINT.attempts > 0 ? `${shotChartRegions.TWO_POINT.makes}/${shotChartRegions.TWO_POINT.attempts}` : "0/0"}
          </Typography>
        </Box>
        {/* three point */}
        <Box sx={{ position: "absolute", top: 160, left: "75%" }}>
          <Typography sx={{ fontSize: 14, fontWeight: "bold", textAlign: "center", color: 'white' }}>
            {shotChartRegions.THREE_POINT.attempts > 0 ? `${shotChartRegions.THREE_POINT.percentage}` : "0%"}
          </Typography>
          <Typography sx={{ fontSize: 12, textAlign: "center", color: 'white' }}>
            {shotChartRegions.THREE_POINT.attempts > 0 ? `${shotChartRegions.THREE_POINT.makes}/${shotChartRegions.THREE_POINT.attempts}` : "0/0"}
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Box style={{ position: 'relative', alignItems: "center", alignContent:'center', width: '100%' }}>
      {getRegionLabels()}
      <svg
        width={312}
        height={223}
        viewBox="0 0 312 223"
        fill="none"
      >
        <path
          stroke="#78808D"
          strokeWidth={2}
          strokeMiterlimit={10}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M1 2H311V222H1z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M106 131.547h100V1.549h70V61.55c0 66.248-53.711 119.996-119.996 119.996C89.72 181.547 36.009 127.799 36 61.551V1.55h70v129.998z"
          fill={getRegionColor(shotChartRegions.TWO_POINT.percentage, shotChartRegions.TWO_POINT.attempts).color}
          fillOpacity={getRegionColor(shotChartRegions.TWO_POINT.percentage, shotChartRegions.TWO_POINT.attempts).opacity}
        />
        <path
          d="M206 131.547v1a1 1 0 001-1h-1zm-100 0h-1a1 1 0 001 1v-1zM206 1.549v-1a1 1 0 00-1 1h1zm70 0h1a1 1 0 00-1-1v1zM36 61.55h-1 1zM36 1.55v-1a1 1 0 00-1 1h1zm70 0h1a1 1 0 00-1-1v1zm100 128.998H106v2h100v-2zM205 1.549v129.998h2V1.549h-2zm71-1h-70v2h70v-2zm1 61.002V1.55h-2V61.55h2zM156.004 182.547C222.841 182.547 277 128.351 277 61.551h-2c0 65.696-53.264 118.996-118.996 118.996v2zM35 61.551c.01 66.8 54.168 120.996 121.004 120.996v-2c-65.731 0-118.995-53.3-119.004-118.996h-2zM35 1.55V61.55h2V1.55h-2zm71-1H36v2h70v-2zm1 130.998V1.549h-2v129.998h2z"
          fill="#78808D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1 221.547h310v-220H1v220zM276 61.551c0 66.248-53.711 119.996-119.996 119.996C89.72 181.547 36.009 127.799 36 61.551V1.55h240V61.55z"
          fill={getRegionColor(shotChartRegions.THREE_POINT.percentage, shotChartRegions.THREE_POINT.attempts).color}
          fillOpacity={getRegionColor(shotChartRegions.THREE_POINT.percentage, shotChartRegions.THREE_POINT.attempts).opacity}
        />
        <path
          d="M311 221.547v1a1 1 0 001-1h-1zm-310 0H0a1 1 0 001 1v-1zm310-220h1a1 1 0 00-1-1v1zm-310 0v-1a1 1 0 00-1 1h1zM36 61.55h-1 1zM36 1.55v-1a1 1 0 00-1 1h1zm240 0h1a1 1 0 00-1-1v1zm35 218.998H1v2h310v-2zm-1-219v220h2v-220h-2zm-309 1h310v-2H1v2zm1 219v-220H0v220h2zm154.004-39C222.841 182.547 277 128.351 277 61.551h-2c0 65.696-53.264 118.996-118.996 118.996v2zM35 61.551c.01 66.8 54.168 120.996 121.004 120.996v-2c-65.731 0-118.995-53.3-119.004-118.996h-2zM35 1.55V61.55h2V1.55h-2zm241-1H36v2h240v-2zm1 61.002V1.55h-2V61.55h2z"
          fill="#78808D"
        />
        <path
          d="M206 131.547H106v-130h100v130z"
          fill={getRegionColor(shotChartRegions.PAINT.percentage, shotChartRegions.PAINT.attempts).color}
          fillOpacity={getRegionColor(shotChartRegions.PAINT.percentage, shotChartRegions.PAINT.attempts).opacity}
          stroke="#78808D"
          strokeWidth={2}
          strokeMiterlimit={10}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Box>
  );
}

export default ShotChartRegionMap;