import React, { useState, useEffect } from "react";
import { Box, Typography, Modal, Fade, Backdrop } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { analytics } from "../shared/utils";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "90%",
  maxWidth: 600,
  bgcolor: '#16181A',
  boxShadow: 24,
  borderRadius: 2,
  textAlign: "center",
  pb: 4,
};

type InputProps = {
  visible: boolean;
  setVisible: (visible: boolean) => void;
}

/**
 * MobilePopup
 * @param {boolean} visible - Whether the popup is visible
 * @param {function} setVisible - Function to set the visibility of the popup
 * @returns {JSX.Element}
 */
const MobilePopup = ({ visible, setVisible }: InputProps) => {
  const [isBig, setIsBig] = useState<boolean>(window.innerWidth > 500);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setIsBig(window.innerWidth > 500);
    });
  }, []);

  useEffect(() => {
    if (visible) {
      analytics.track("Web/Popup: opened");
    } else {
      analytics.track("Web/Popup: closed");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return (
    <Box>
      <Modal 
        open={visible} 
        onClose={() => setVisible(false)}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{ backdrop: { timeout: 500 } }}
      >
        <Fade in={visible}>
          <Box sx={style}>
            <img 
              src="/logos/x.svg" 
              alt="Close" 
              style={{ width: 40, height: 40, objectFit: "cover", position: "absolute", top: 10, right: 10 }} 
              onClick={() => setVisible(false)}
            />
            <img 
              src="/images/background-2.jpg" 
              alt="Hooper" 
              style={{
                width: "100%", 
                height: "240px", 
                objectFit: "cover", 
                borderTopLeftRadius: 4, 
                borderTopRightRadius: 4, 
              }} 
            />
            <Box sx={{ px: isBig ? 4 : 2, pt: 2 }}>
              <Typography 
                sx={{ 
                  color: "white", 
                  fontSize: isBig ? 50 : 32, 
                  textTransform: "uppercase", 
                  fontFamily: "Bebas Neue",
                }}
              >
                Elevate your game
              </Typography>
              <Typography 
                sx={{ 
                  color: "#668cff", 
                  fontSize: isBig ? 18 : 16, 
                  fontWeight: "bold" 
                }} 
                gutterBottom
              >
                Create your highlights, shot by shot.
              </Typography>
              <Typography 
                sx={{ 
                  color: "white", 
                  fontSize: isBig ? 16 : 14, 
                  opacity: 0.5 
                }}
              >
                This website is in beta and has limited features.
              </Typography>
              <Typography 
                sx={{ 
                  color: "white", 
                  fontSize: isBig ? 16 : 14 
                }}
              >
                For the full Hooper experience, download the mobile app.
              </Typography>
              <Grid container sx={{ justifyContent: "center", alignItems: "center", mt: 3 }} gap={2}>
                <Grid sx={{ backgroundColor: "#5040FF", borderRadius: 2, px: 2, pt: 1.5, pb: 0.5, width: !isBig ? "100%" : "auto" }}>
                  <Box onClick={() => window.open("https://testflight.apple.com/join/WxmlfGUl", "_blank")}>
                    <img src="/images/testflight.svg" alt="Apple TestFlight" />
                  </Box>
                </Grid>
                <Grid sx={{ backgroundColor: "#5040FF", borderRadius: 2, px: 2, pt: 1.5, pb: 0.5, width: !isBig ? "100%" : "auto" }}>
                  <Box onClick={() => window.open("https://play.google.com/store/apps/details?id=com.builtdifferent.hooper", "_blank")}>
                    <img src="/images/googleapp.svg" alt="Google App Store" />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </Box>
  );
}

export default MobilePopup;