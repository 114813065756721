import React from "react";
import { Box, Typography } from "@mui/material";
import { analytics } from "../../shared/utils";

type InputProps = {
  selectedMode: number;
  setSelectedMode: (x: number) => void;
}

/**
 * ModeToggle
 * Component for toggling between game cards and profile info
 */
const ModeToggle = ({ selectedMode, setSelectedMode }: InputProps) => {
  return (
    <Box
      sx={{
        justifyContent: "space-between",
        alignItems: "center",
        display: "flex",
        flexDirection: "row",
        backgroundColor: "#202429",
        borderRadius: 20,
      }}
    >
      <Box 
        onClick={() => {
          setSelectedMode(0);
          analytics.track("Web/Profile: toggle mode", { mode: 0 });
        }}
        sx={{
          backgroundColor: (selectedMode === 0) ? "white" : "#202429",
          px: 4,
          py: 1,
          borderRadius: 16,
          cursor: "pointer",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 2 }}>
          <Typography style={{ color: (selectedMode === 0) ? "#202429" : "white", fontSize: 20 }}>
            Games
          </Typography>
        </Box>
      </Box>
      <Box 
        onClick={() => {
          setSelectedMode(1);
          analytics.track("Web/Profile: toggle mode", { mode: 1 });
        }}
        sx={{
          backgroundColor: (selectedMode === 1) ? "white" : "#202429",
          px: 4,
          py: 1,
          borderRadius: 16,
          cursor: "pointer",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 2 }}>
          <Typography style={{ color: (selectedMode === 1) ? "#202429" : "white", fontSize: 20 }}>
            Workouts
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default ModeToggle;
