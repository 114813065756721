import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useMediaQuery, useTheme } from '@mui/material';
import { 
  TRIPOD_SETUP, 
  GAME_MODE, 
  SHOOTAROUND_MODE, 
  POINTS_TWOS_AND_THREES, 
  POINTS_ONES_AND_TWOS,
  SESSION_TAGGED, 
  SESSION_PROCESSED, 
  ACTION_SHOT,
  SESSION_SOLO,
} from "../../shared/constants";
import { 
  aggregateActionsByTeamV4, 
  getPointsFromShotsV4, 
  isShotIn,
} from "../../shared/utils";
import type { 
  FBUser, 
  FBSession, 
  FBClusterTagV4, 
  ClusterActionsV4 
} from "../../shared/types";

type InputProps = {
  session: FBSession;
  clusters: FBClusterTagV4[];
  shots: ClusterActionsV4[];
  owner?: FBUser;
}

/**
 * Session summary component
 * @param session (FBSession)
 * @param clusters (FBClusterTagV4[])
 * @param shots (ClusterActionsV4[])
 * @param owner (FBUser)
 */
const Summary = ({ session, clusters = [], shots = [], owner }: InputProps) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

   // Get the title for the left side description
   const OutcomeTitle = (): string => {
    var title: string;
    if (
      session.status !== SESSION_TAGGED ||
      session.sessionMode === SHOOTAROUND_MODE || 
      !session.hasTeams
    ) {
      // For shootarounds and non-tagged sessions, show generic points
      title = 'Shots taken'
    } else {
      const pointSystem = session.pointSystem || POINTS_ONES_AND_TWOS;
      const teamNames = session.teamNames || ["Team 1", "Team 2"];
      const team1Shots = aggregateActionsByTeamV4(clusters, shots, ACTION_SHOT, 1);
      const team2Shots = aggregateActionsByTeamV4(clusters, shots, ACTION_SHOT, 2);
      const points1 = getPointsFromShotsV4(pointSystem, team1Shots.shots);
      const points2 = getPointsFromShotsV4(pointSystem, team2Shots.shots);
      if (points1 > points2) {
        title = teamNames[0];
      } else if (points1 === points2) {
        title = "Tie";
      } else {
        title = teamNames[1];
      }
    }
    return title;
  }

  const OutcomeScore = (): string => {
    var outcome: string;
    const pointSystem = session.pointSystem || POINTS_ONES_AND_TWOS;
    if (session.status === SESSION_PROCESSED) {
      // If we are not tagged yet, just return total points
      const numShots = shots.map(shot => shot.shots.length);
      const total = numShots.reduce((tot, x) => tot + x, 0);
      outcome = `${total}`;
    } else {
      if (session.sessionMode === SHOOTAROUND_MODE || !session.hasTeams) {
        if (session.sessionType === SESSION_SOLO && clusters.length > 0) {
          // If solo then we can report makes over takes
          const numMakes = shots[0].shots.filter(shot => isShotIn(shot)).length;
          const numTakes = shots[0].shots.length;
          outcome = `${numMakes}/${numTakes}`;
        } else {
          // If lots of people working out, nothing to do but return total points
          const numShots = shots.map(shot => shot.shots.length);
          const total = numShots.reduce((tot, x) => tot + x, 0);
          outcome = `${total}`;
        }
      } else {
        // If we have teams (even partially tagged, then we need to report current score)
        const team1Shots = aggregateActionsByTeamV4(clusters, shots, ACTION_SHOT, 1);
        const team2Shots = aggregateActionsByTeamV4(clusters, shots, ACTION_SHOT, 2);
        const points1 = getPointsFromShotsV4(pointSystem, team1Shots.shots);
        const points2 = getPointsFromShotsV4(pointSystem, team2Shots.shots);
        if (points1 >= points2) {
          outcome = `${points1}-${points2}`;
        } else {
          outcome = `${points2}-${points1}`;
        }
      }
    }
    return outcome;
  }

  // Get the subtitle for the left side description
  const OutcomeSubtitle = (): string => {
    var subtitle: string;
    if (session.sessionMode === GAME_MODE) {
      subtitle = session.sessionType;
    } else {
      subtitle = `${session.numPlayers || 1}`;
    }
    return subtitle;
  }

  const PointsSection = () => {
    return (  
      <Box>
        <Typography sx={{ fontSize: 24, fontWeight: "bold", color: "white" }}>
          {OutcomeTitle()}
        </Typography>
        <Typography sx={{ fontSize: 36, fontWeight: "bold", color: "white" }}>
          {OutcomeScore()}
        </Typography>
        <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
          <img src="/icons/user.svg" alt="check" width={18} height={18} />
          <Typography sx={{ fontSize: 20, color: "#78808D" }}>
            {OutcomeSubtitle()}
          </Typography>
        </Stack>
      </Box>
    )
  }

  const InfoSection = () => {
    return (
      <Box sx={{ textAlign: "left" }}>
        <Typography sx={{ fontSize: 18, color: "#ABB2BF" }}>
          {new Date(session.createdAt * 1000).toLocaleString('en-US', {
            weekday: 'short', // Abbreviated day of the week
            month: 'short',   // Abbreviated month name
            day: 'numeric',   // Day of the month
            year: '2-digit',  // Abbreviated year
            hour: '2-digit',  // Hour
            minute: '2-digit',  // Minute
          })}
        </Typography>
        {session.city && session.state && (
          <Stack direction="row" spacing={1} alignItems="center" sx={{ mb: 1 }}>
            <img src="/icons/map-pin.svg" alt="location" width={18} height={18} />
            <Typography sx={{ fontSize: 18, color: "#ABB2BF" }}>
              {session.city}, {session.state}
            </Typography>
          </Stack>
        )}
        {owner && (
          <Stack direction="row" spacing={1} alignItems="center">
            {owner.profilePicture && (
              <img src={owner.profilePicture} alt="user" width={24} height={24} style={{ borderRadius: "50%" }} />
            )}
            <Typography sx={{ fontSize: 18, color: "#ABB2BF" }}>
              Filmed by <span style={{ color: "#80B5FF" }}>{owner.username}</span>
            </Typography>
          </Stack>
        )}
        <Box sx={{ display: "flex", flexDirection: "row", gap: 3, mt: 1 }}>
          <Stack direction="row" spacing={1} alignItems="center">
            <img src={session.recordingSetup === TRIPOD_SETUP ? "/icons/check.svg" : "/icons/x.svg"} alt="tripod" width={18} height={18} />
            <Typography sx={{ fontSize: 18, color: "#78808D" }}>
              {session.recordingSetup === TRIPOD_SETUP ? "Tripod" : "Handheld"}
            </Typography>
          </Stack>
          <Stack direction="row" spacing={1} alignItems="center">
            <img src="/icons/clock.svg" alt="clock" width={18} height={18} />
            <Typography sx={{ fontSize: 18, color: "#78808D" }}>
              {Math.max(Math.round(session.sessionLength / 60), 1)} min
            </Typography>
          </Stack>
          <Stack direction="row" spacing={1} alignItems="center">
            <img src="/icons/hexagon.svg" alt="hexagon" width={18} height={18} />
            <Typography sx={{ fontSize: 18, color: "#78808D" }}>
              {
                session.pointSystem === POINTS_TWOS_AND_THREES ? "2s & 3s" :
                session.pointSystem === POINTS_ONES_AND_TWOS ? "1s & 2s" :
                "1s only"
              }
            </Typography>
          </Stack>
        </Box>
      </Box>
    )
  }

  return (
    <Box sx={{ backgroundColor: "#16181A", borderRadius: 2, py: 4, px: isSmallScreen ? 4 : 8 }}>
      <Grid 
        container 
        spacing={2} 
        direction={{ xs: "column", sm: "row" }} // Stack vertically on small screens
        alignItems={{ xs: "center", sm: "flex-start" }} // Center items on small screens
      >
        <Grid size={{ xs: 12, sm: 6 }}>
          <PointsSection />
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }} sx={{ mt: isSmallScreen ? 2 : 0 }}>
          <InfoSection />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Summary;
