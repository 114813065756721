import React from "react";
import { Box, Typography } from "@mui/material";
import { GROUND_SETUP, POINTS_TWOS_AND_THREES } from "../../shared/constants";
import { 
  isShotIn, 
  isShotTwoPointer, 
  getPointsFromShotsV4, 
  getClusterIdsByTeamV4, 
  safeDivideAndRound 
} from "../../shared/utils";
import type { FBSession, FBClusterTagV4, ClusterActionsV4, FBUser } from "../../shared/types";

type InputProps = {
  session: FBSession;
  clusterTags: FBClusterTagV4[];
  clusterShots: ClusterActionsV4[];
  clusterRebounds: ClusterActionsV4[];
  clusterAssists: ClusterActionsV4[];
  taggedUsers: FBUser[];
  teamId?: number;
  teamName?: string;
}

const BoxScores = ({
  session,
  clusterTags,
  clusterShots,
  clusterRebounds,
  clusterAssists,
  taggedUsers,
  teamId,
  teamName,
}: InputProps) => {
  // Find user from tagged users
  const getTaggedUser = (userId: string): FBUser | undefined => {
    return taggedUsers.find(user => user.userId === userId);
  }
  // getRow - Build a single row of the stats table
  const getRow = (
    rowIndex: number,
    userId: string | null | undefined,  // used to identify current user
    content: string[], 
    widths: number[],
    hasDivider: boolean,
    makeBold: boolean,
    textColor: string,
  ) => {
    const player = userId ? getTaggedUser(userId) : undefined;
    const totalWidth = widths.reduce((sum, width) => sum + width, 0);
    return (
      <Box key={`row-${rowIndex}`}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            pb: 1,
          }}
        >
          {content.map((item, index) => {
            const width = `${widths[index]}px`;
            let textStyle: any = {
              color: textColor,
              fontSize: 20,
              fontWeight: makeBold ? "bold" : "normal",
            };
            if (rowIndex > 0 && index === 0) {
              // Special case for the first column of every row. This represents 
              // the name of the player. We will return a compnoent that includes a 
              // profile picture, and a clickable link to fix this person's clusters
              return (
                <Box 
                  sx={{ minWidth: width, display: "flex", flexDirection: "row", alignItems: "center", gap: 2 }}
                  key={`row-${rowIndex}-item-${index}`}
                >
                  <Box>
                    {(player && player.profilePicture) ? (
                      <img src={player.profilePicture} alt="profile" width={36} height={36} style={{ borderRadius: 100 }} />
                    ) : (
                      <img src="/images/blank-profile.webp" alt="profile" width={36} height={36} style={{ borderRadius: 100 }} />
                    )}
                  </Box>
                  <Typography style={textStyle}>
                    {item}
                  </Typography>
                </Box>
              );
            } else {
              if (index === 0) {
                textStyle["textAlign"] = "left";
              } else {
                textStyle["textAlign"] = "center";
              }
              return (
                <Typography
                  key={`row-${rowIndex}-item-${index}`}
                  style={{...textStyle, minWidth: width}}
                >
                  {item}
                </Typography>
              );
            }
          })}
        </Box>
        {hasDivider && (
          <Box 
            style={{ 
              backgroundColor: "black", 
              height: "2px", 
              width: "100%", 
              minWidth: `${totalWidth}px`, 
              marginBottom: 16 
            }} 
          />
        )}
      </Box>
    );
  }
  /**
   * Stats table for ground setup
   */
  const LiteStatsTable = () => {
    const headers = ["Player", "Points", "FG", "FG%", "REB", "AST"];
    const widths = [200, 80, 80, 80, 80, 80];
    const headerRow = getRow(0, undefined, headers, widths, true, true, "#ABB2BF");
    // Find clusters, shots, rebounds, and assists for the team
    let clusters: FBClusterTagV4[];
    let shots: ClusterActionsV4[];
    let rebounds: ClusterActionsV4[];
    let assists: ClusterActionsV4[];
    if (teamId) {
      const [team1ClusterIds, team2ClusterIds] = getClusterIdsByTeamV4(clusterTags);
      const teamClusterIds = (teamId === 1) ? team1ClusterIds : team2ClusterIds;
      clusters = clusterTags.filter(cluster => teamClusterIds.includes(cluster.clusterId!));
      shots = clusterShots.filter(cluster => teamClusterIds.includes(cluster.clusterId));
      rebounds = clusterRebounds.filter(cluster => teamClusterIds.includes(cluster.clusterId));
      assists = clusterAssists.filter(cluster => teamClusterIds.includes(cluster.clusterId));
    } else {
      clusters = clusterTags;
      shots = clusterShots;
      rebounds = clusterRebounds;
      assists = clusterAssists;
    }
    // Loop through players and statistics
    const statRows = shots.map((curShots, index) => {
      const curRebounds = rebounds[index];
      const curAssists = assists[index];
      const cluster = clusters.find(cluster => cluster.clusterId === curShots.clusterId)!;
      const numMakes = curShots.shots.filter(shot => isShotIn(shot)).length;
      const numTakes = curShots.shots.length;
      const points = getPointsFromShotsV4(session.pointSystem || POINTS_TWOS_AND_THREES, curShots.shots, true);
      const fgPerc = safeDivideAndRound(numMakes, numTakes);
      const numRebounds = curRebounds.shots.length;
      const numAssists = curAssists.shots.length;
      const row = getRow(
        index + 1, 
        cluster.userId,
        [
          (cluster.clusterClass === -1) ? "Unclaimed" : cluster.name ? cluster.name : `Player ${cluster.clusterClass + 1}`, 
          points.toString(),
          numMakes.toString() + "/" + numTakes.toString(),
          `${fgPerc}`,
          numRebounds.toString(),
          numAssists.toString(),
        ],
        widths,
        (index === shots.length - 1) ? false : true,
        false,
        "white",
      );
      return row;
    });
    return (
      <Box >
        {statRows.length > 0 && headerRow}
        {statRows.length > 0 && statRows}
        {statRows.length === 0 && (
          <Box sx={{ mt: 2, mb: 1 }}>
            <Typography sx={{ textAlign: "center", fontSize: 20, color: "white", opacity: 0.5 }}>
              No players to show. Please assign teams in the mobile app to see stats.
            </Typography>
          </Box>
        )}
      </Box>
    );
  }
  
  /**
   * Stats table when paint has not been tagged
   */
  const HiddenStatsTable = () => {
    const headers = ["Player", "Pts", "FG", "FG%", "3PT", "3P%", "REB", "AST"];
    const widths = [200, 80, 80, 80, 80, 80, 80, 80];
    const headerRow = getRow(0, undefined, headers, widths, true, true, "#ABB2BF");
    let clusters: FBClusterTagV4[];
    let shots: ClusterActionsV4[];
    let rebounds: ClusterActionsV4[];
    let assists: ClusterActionsV4[];
    if (teamId) {
      const [team1Ids, team2Ids] = getClusterIdsByTeamV4(clusterTags);
      const teamIds = (teamId === 1) ? team1Ids : team2Ids;
      clusters = clusterTags.filter(cluster => teamIds.includes(cluster.clusterId!));
      shots = clusterShots.filter(cluster => teamIds.includes(cluster.clusterId));
      rebounds = clusterRebounds.filter(cluster => teamIds.includes(cluster.clusterId));
      assists = clusterAssists.filter(cluster => teamIds.includes(cluster.clusterId));
    } else {
      clusters = clusterTags;
      shots = clusterShots;
      rebounds = clusterRebounds;
      assists = clusterAssists;
    }
    // Loop through players and statistics
    const statRows = shots.map((curShots, index) => {
      const curRebounds = rebounds[index];
      const curAssists = assists[index];
      const cluster = clusters.find(cluster => cluster.clusterId === curShots.clusterId)!;
      const numMakes = curShots.shots.filter(shot => isShotIn(shot)).length;
      const numTakes = curShots.shots.length;
      const points = getPointsFromShotsV4(session.pointSystem || POINTS_TWOS_AND_THREES, curShots.shots, true);
      const fgPerc = safeDivideAndRound(numMakes, numTakes);
      const numRebounds = curRebounds.shots.length;
      const numAssists = curAssists.shots.length;
      const row = getRow(
        index + 1, 
        cluster.userId,
        [
          (cluster.clusterClass === -1) ? "Unclaimed" : cluster.name ? cluster.name : `Player ${cluster.clusterClass + 1}`, 
          points.toString(),
          numMakes.toString() + "/" + numTakes.toString(),
          fgPerc.toString(),
          "?",
          "?",
          numRebounds.toString(),
          numAssists.toString(),
        ],
        widths,
        (index === shots.length - 1) ? false : true,
        false,
        "white",
      );
      return row;
    });
    return (
      <Box>
        {statRows.length > 0 && headerRow}
        {statRows.length > 0 && statRows}
        {statRows.length === 0 && (
          <Box sx={{ mt: 2, mb: 1 }}>
            <Typography sx={{ textAlign: "center", fontSize: 20, color: "white", opacity: 0.5 }}>
              No players to show. Please assign teams in the mobile app to see stats.
            </Typography>
          </Box>
        )}
      </Box>
    );
  }
  
  /**
   * Stats table when paint has been tagged
   */
  const FullStatsTable = () => {
    const headers = ["Player", "Pts", "FG", "FG%", "3PT", "3P%", "REB", "AST"];
    const widths = [200, 80, 80, 80, 80, 80, 80, 80];
    const headerRow = getRow(0, undefined, headers, widths, true, true, "#ABB2BF");
    let clusters: FBClusterTagV4[];
    let shots: ClusterActionsV4[];
    let rebounds: ClusterActionsV4[];
    let assists: ClusterActionsV4[];
    if (teamId) {
      const [team1Ids, team2Ids] = getClusterIdsByTeamV4(clusterTags);
      const teamIds = (teamId === 1) ? team1Ids : team2Ids;
      clusters = clusterTags.filter(cluster => teamIds.includes(cluster.clusterId!));
      shots = clusterShots.filter(cluster => teamIds.includes(cluster.clusterId));
      rebounds = clusterRebounds.filter(cluster => teamIds.includes(cluster.clusterId));
      assists = clusterAssists.filter(cluster => teamIds.includes(cluster.clusterId));
    } else {
      clusters = clusterTags;
      shots = clusterShots;
      rebounds = clusterRebounds;
      assists = clusterAssists;
    }
    // Loop through players and statistics
    const statRows = shots.map((curShots, index) => {
      const curRebounds = rebounds[index];
      const curAssists = assists[index];
      const cluster = clusters.find(cluster => cluster.clusterId === curShots.clusterId)!;
      const numMakes = curShots.shots.filter(shot => isShotIn(shot)).length;
      const numTakes = curShots.shots.length;
      const numMakes3pt = curShots.shots.filter(shot => !isShotTwoPointer(shot) && isShotIn(shot)).length;
      const numTakes3pt = curShots.shots.filter(shot => !isShotTwoPointer(shot)).length;
      const points = getPointsFromShotsV4(session.pointSystem || POINTS_TWOS_AND_THREES, curShots.shots, false);
      const fgPerc = safeDivideAndRound(numMakes, numTakes);
      const threePerc = safeDivideAndRound(numMakes3pt, numTakes3pt);
      const numRebounds = curRebounds.shots.length;
      const numAssists = curAssists.shots.length;
      const row = getRow(
        index + 1, 
        cluster.userId,
        [
          (cluster.clusterClass === -1) ? "Unclaimed" : cluster.name ? cluster.name : `Player ${cluster.clusterClass + 1}`, 
          points.toString(),
          numMakes.toString() + "/" + numTakes.toString(),
          `${fgPerc}`,
          numMakes3pt.toString() + "/" + numTakes3pt.toString(),
          `${threePerc}`,
          numRebounds.toString(),
          numAssists.toString(),
        ],
        widths,
        (index === shots.length - 1) ? false : true,
        false,
        "white",
      );
      return row;
    });
    return (
      <Box 
        sx={{ 
          overflowX: "auto", 
          whiteSpace: "nowrap", 
          "&::-webkit-scrollbar": {
            height: "8px", // Adjust scrollbar height
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#888", // Scrollbar thumb color
            borderRadius: "4px",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            backgroundColor: "#555", // Hover color
          },
        }}
      >
        {statRows.length > 0 && headerRow}
        {statRows.length > 0 && statRows}
        {statRows.length === 0 && (
          <Box sx={{ mt: 2, mb: 1 }}>
            <Typography sx={{ textAlign: "center", fontSize: 20, color: "white", opacity: 0.5 }}>
              No players to show. Please assign teams in the mobile app to see stats.
            </Typography>
          </Box>
        )}
      </Box>
    );
  }
  
  return (
    <Box sx={{ my: 4}}>
      <Typography sx={{ textAlign: "left", color: "white", fontWeight: "bold", fontSize: 18, mb: 2 }}>
        {teamName}
      </Typography>
      <Box 
        sx={{ 
          width: "100%", 
          display: "inline-block", 
          overflowX: "auto", 
          borderRadius: 2, 
          backgroundColor: "#16181A", 
          px: 4, 
          py: 2,
        }}
      >
        {session.recordingSetup === GROUND_SETUP 
          ? LiteStatsTable() 
          : session.annotatedPaintKeypoints 
            ? FullStatsTable() 
            : HiddenStatsTable()
        }
      </Box>
    </Box>
  )
};

export default BoxScores;
