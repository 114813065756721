import React from "react";
import { Box } from "@mui/material";
import ReactPlayer from 'react-player';
import type { FBSession } from "../../shared/types";

type InputProps = {
  session: FBSession;
}

const Footage = ({ session }: InputProps) => {
  return (
    <Box 
      style={{ position: "relative", width: "100%", paddingTop: "56.25%" }}
      sx={{ borderRadius: 2, overflow: "hidden" }}
    >
      <ReactPlayer 
        url={session.videoUrl!}
        light={session.videoPreview ? session.videoPreview : true}
        controls={true}
        style={{ position: "absolute", top: 0, left: 0 }}
        width="100%"
        height="100%"
      />
    </Box>
  );
};

export default Footage;
