import React, { useState } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import { auth } from "../../shared/firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import toast from 'react-hot-toast';
import { analytics } from "../../shared/utils";

type InputProps = {
  onSuccess: (userId: string, isNewUser: boolean) => void;
}

/**
 * EmailAuth
 * Component for logging in with email and password
 */
const EmailAuth = ({ onSuccess }: InputProps) => {
  const [email, setEmail] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [hasError, setHasError] = useState<boolean>(false);

  const onLogin = async () => {
    if (!email || !password) {
      toast.error("Please enter an email and password");
      analytics.track("Web/Login/Email: login error", { error: "Empty email or password" });
      return;
    }
    try { 
      const result = await signInWithEmailAndPassword(auth, email, password);
      const user = result.user;
      const isNewUser = user.metadata.creationTime === user.metadata.lastSignInTime;
      setHasError(false);
      onSuccess(user.uid, isNewUser);
    } catch (error: any) {
      // Case when the pw is wrong OR they're not using their original sign in method
      if (error.code === "auth/wrong-password") {
        toast.error("Incorrect password or you may want to try the original sign in method.");
      } else {
        console.error(`Error logging in: ${error}`);
        toast.error("Something went wrong. Please try again.");
      }
      analytics.track("Web/Login/Email: login error", { error: error });
      setHasError(true);
    }
  }

  return (
    <Box
      component="form"
      sx={{ '& .MuiTextField-root': { m: 1, width: '25ch' } }}
      noValidate
      autoComplete="off"
    >
      <Box>
        <TextField
          required
          error={hasError}
          id="outlined-error"
          label="Email"
          value={email}
          variant="filled"
          onChange={(e) => setEmail(e.target.value)}
          sx={{
            minWidth: 300,
            "& .MuiFilledInput-root": {
              backgroundColor: "#2d2d2d", // Change the background color
              color: "white", // Change the text color
            },
            "& .MuiInputLabel-root": {
              color: "white", // Change the label color
            },
            "& .MuiFilledInput-underline:before": {
              borderBottomColor: "white", // Change the underline color
            },
            "& .MuiFilledInput-underline:hover:before": {
              borderBottomColor: "yellow", // Change underline color on hover
            },
            "& .MuiFilledInput-underline:after": {
              borderBottomColor: "#5040FF", // Change underline color on focus
            },
          }}
        />
      </Box>
      <Box>
        <TextField
          required
          error={hasError}
          id="outlined-error"
          label="Password"
          value={password}
          variant="filled"
          type="password"
          onChange={(e) => setPassword(e.target.value)}
          sx={{
            minWidth: 300,
            "& .MuiFilledInput-root": {
              backgroundColor: "#2d2d2d", // Change the background color
              color: "white", // Change the text color
            },
            "& .MuiInputLabel-root": {
              color: "white", // Change the label color
            },
            "& .MuiFilledInput-underline:before": {
              borderBottomColor: "white", // Change the underline color
            },
            "& .MuiFilledInput-underline:hover:before": {
              borderBottomColor: "yellow", // Change underline color on hover
            },
            "& .MuiFilledInput-underline:after": {
              borderBottomColor: "#5040FF", // Change underline color on focus
            },
          }}
        />
      </Box>
      <Box sx={{ mt: 4 }}>
        <Button 
          onClick={onLogin}
          sx={{ 
            minWidth: 300,
            borderRadius: 50,
            backgroundColor: "#5040FF",
            color: "white",
            fontWeight: "bold",
            py: 2,
            textTransform: "none",
          }}
        >
          <Typography sx={{ fontWeight: "bold", fontSize: 18 }}>Log in with email</Typography>
        </Button>
      </Box>
    </Box>
  );
};

export default EmailAuth;
