import { initializeApp } from "firebase/app";
import { getAuth, setPersistence, browserLocalPersistence } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCvQPglKz_JxoF529qy_qx9rOkqJLyN43I",
  authDomain: "hooper-ac7b0.firebaseapp.com",
  projectId: "hooper-ac7b0",
  storageBucket: "hooper-ac7b0.appspot.com",
  messagingSenderId: "524163598900",
  appId: "1:524163598900:web:893c1f7a85799fd5879861",
  measurementId: "G-DMT985P13Z"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
setPersistence(auth, browserLocalPersistence)
.catch((error) => console.error("Failed to set persistence:", error));
export { auth };
export const analytics = getAnalytics(app);
export default app;
