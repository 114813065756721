import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { fetchSessionCards, fetchUser } from "../shared/services";
import type { SessionCard } from "../shared/types";
import EmptyCardGrid from "./profile/EmptyCardGrid";
import { useAuth } from "./AuthProvider";
import { auth } from "../shared/firebase";
import { signOut } from "firebase/auth";
import { CURRENT_SESSION_VERSION, GAME_MODE, SHOOTAROUND_MODE } from "../shared/constants";
import { SESSION_PROCESSED, SESSION_TAGGED, SESSION_WAITING, SESSION_UPLOADED } from "../shared/constants";
import type { FBUser } from "../shared/types";
import { Box, Typography } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import MobilePopup from "./MobilePopup";
import Grid from "@mui/material/Grid2";
import toast from 'react-hot-toast';
import LoadingOverlay from "./LoadingOverlay";
import ProfileInfo from "./profile/ProfileInfo";
import GameCard from "./profile/GameCard";
import ModeToggle from "./profile/ModeToggle";
import { analytics } from "../shared/utils";
import Cookies from 'js-cookie';

const ProfileView = () => {
  const { id: paramId } = useParams();
  const navigate = useNavigate();
  // Fetch all the user cards
  const [user, setUser] = useState<FBUser>();
  const [cards, setCards] = useState<SessionCard[]>([]);
  const [openPopup, setOpenPopup] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [mode, setMode] = useState<number>(0);
  
  // Fetch the user from the context
  const { user: currentUser } = useAuth();
  const userId = paramId ? paramId : currentUser?.userId;
  const isLoggedIn = !!currentUser;

  useEffect(() => {
    const alreadyViewed = Cookies.get("hooper_app_popup_viewed") === '1';
    if (alreadyViewed) {
      setOpenPopup(false);
    } else {
      setOpenPopup(true);
      // This way we don't show the popup again for 30 days
      Cookies.set("hooper_app_popup_viewed", "1", { expires: 30 });
    }
    analytics.screen("Web/Profile", { userId: userId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  const fetchData = async () => {
    if (!userId) return;
    // Fetch user from firebase
    try {
      const user = await fetchUser(userId);
      const cards = await fetchSessionCards(userId, 0, 100);
      setUser(user);
      setCards(cards
        .filter(card => (
          card.sessionStatus === SESSION_PROCESSED || 
          card.sessionStatus === SESSION_TAGGED || 
          card.sessionStatus === SESSION_WAITING || 
          card.sessionStatus === SESSION_UPLOADED
        ))
        .sort((a, b) => b.createdAt - a.createdAt)
      );
    } catch (e) {
      toast.error("Something went wrong. Please try again later.");
      setUser(undefined);
      setCards([]);
    }
  }

  // Fetch the user cards
  useEffect(() => {
    if (!userId) return;
    setLoading(true);
    fetchData()
    .catch(() => toast.error("Something went wrong. Please try again later."))
    .finally(() => setLoading(false));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  // Show loading overlay
  if (loading) return <LoadingOverlay />;

  // Render the cards
  const CardGrid = () => {
    let data: SessionCard[] = [];
    if (mode === 0) {
      data = cards.filter(card => card.sessionMode === GAME_MODE);
    } else {
      data = cards.filter(card => card.sessionMode === SHOOTAROUND_MODE);
    }
    // Show empty card grid if there are no cards
    if (data.length === 0) return <EmptyCardGrid />;
    return (
      <Grid container spacing={1} sx={{ maxWidth: 620, mx: 1 }}>
        {data.map(card => {
          const isClickable = 
            (card.version === CURRENT_SESSION_VERSION) && 
            (card.sessionStatus === SESSION_PROCESSED || card.sessionStatus === SESSION_TAGGED);
          return (
            <Grid size={{ xs: 6, sm: 6, md: 4, lg: 4 }} key={`gamecard-${card.sessionId}`}>
              <GameCard card={card} isClickable={isClickable} />
            </Grid>
          );
        })}
      </Grid>
    );
  }

  const LogoOverlay = () => {
    return (
      <Box sx={{ position: 'absolute', top: 40, left: 40}}>
        <img src="/logos/logo-white.svg" alt="logo" width={100} />
      </Box>
    );
  }

  const LogoutButton = () => {
    return (
      <Box sx={{ position: 'absolute', top: 32, right: 40 }}>
        <Box 
          onClick={() => {
            signOut(auth)
            .then(() => navigate("/login"))
            .catch((e) => {
              toast.error("Something went wrong. Please try again later.")
              console.error("Failed to sign out user", e);
            });
          }} 
          sx={{ 
            cursor: "pointer",
            backgroundColor: "#202429",
            py: 1,
            px: 2,
            borderRadius: 10,
          }}
        >
          <Typography sx={{ color: "white", fontWeight: "bold" }}>
            Logout
          </Typography>
        </Box>
      </Box>
    );
  }

  const Footer = () => {
    return (
      <Box sx={{ mb: 4 }}>
        <Typography sx={{ textAlign: "center", color: "#404040" }}>
          Copyright © 2024 Built Different AI Inc. All rights reserved.
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ minHeight: '100vh', flexDirection: 'column', display: 'flex' }}>
      <MobilePopup visible={openPopup} setVisible={setOpenPopup} />
      <LogoOverlay />
      {isLoggedIn && <LogoutButton />}
      <Box 
        sx={{ 
          flex: 1,
          width: '100%',  // Full viewport width
          height: '100%', // Full viewport height
          backgroundColor: '#0c0c0d', 
          backgroundImage: 'url(/images/dot-pattern.svg)',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          display: 'flex',           // Use flexbox
          flexDirection: 'column',   // Stack content vertically
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box style={{marginTop: 100}} />
        {user && <ProfileInfo user={user} />}
        <Box sx={{ mt: 2, mb: 8 }}>
          <ModeToggle selectedMode={mode} setSelectedMode={setMode} />
        </Box>
        <CardGrid />
        <Box sx={{ my: 8 }} />
        <Footer />
      </Box>
    </Box>
  );
};

export default ProfileView;
