import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import Grid from '@mui/material/Grid2';
import { auth } from "../shared/firebase";
import { onAuthStateChanged } from "firebase/auth";
import EmailAuth from "./login/EmailAuth";
import GoogleAuth from "./login/GoogleAuth";
import AppleAuth from "./login/AppleAuth";
import { useNavigate } from 'react-router-dom';
import { analytics } from "../shared/utils";

/**
 * LoginView
 * Component for logging in
 */
const LoginView = () => {
  const navigate = useNavigate();

  useEffect(() => {
    analytics.screen("Web/Login");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) navigate("/");
    });
    return () => unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const OrDivider = () => (
    <Box sx={{ display: 'flex', flexDirection: "row", alignItems: "center", width: "100%", gap: 4 }}>
      <Box sx={{ flex: 1, height: "4px", backgroundColor: '#202429' }} />
      <Typography sx={{ fontSize: 24, color: "white", fontWeight: "bold", textAlign: "center", paddingHorizontal: 25 }}>
        OR
      </Typography>
      <Box sx={{ flex: 1, height: "4px", backgroundColor: '#202429' }} />
    </Box>
  );

  const CreateCTA = () => {
    return (
      <Typography sx={{ color: "white", textAlign: "center", fontWeight: "bold" }}>
        Not a Hooper yet? <a href="/signup" style={{ color: "#80b5ff", textDecoration: "none", fontWeight: "bold" }}>Join today</a>
      </Typography>
    )
  }

  const onSuccess = (userId: string, isNewUser: boolean) => {
    analytics.track("Web/Login: login success");
    if (isNewUser) {
      navigate("/username");
    } else {
      navigate(`/games`);
    }
  }

  return (
    <Grid container spacing={0} sx={{ height: '100vh'}}>
      <Grid size={{ xl: 6, lg: 6, md: 6, xs: 12, sm: 12 }}>
        <Box 
          sx={{ 
            width: '100%',  // Full viewport width
            height: '100%', // Full viewport height
            backgroundColor: '#0c0c0d', 
            backgroundImage: 'url(/images/dot-pattern.svg)',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            display: 'flex',           // Use flexbox
            flexDirection: 'column',   // Stack content vertically
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <img src="/logos/logo-white.svg" alt="logo" width={150} />
          <Box sx={{ my: 6 }}>
            <EmailAuth onSuccess={onSuccess} />
          </Box>
          <Box sx={{ mb: 2 }}>
            <CreateCTA />
          </Box>
          <OrDivider />
          <Box sx={{ my: 2}}>
            <GoogleAuth onSuccess={onSuccess} />
            <AppleAuth onSuccess={onSuccess} />
          </Box>
        </Box>
      </Grid>
      <Grid size={{ xl: 6, lg: 6, md: 6, xs: 0, sm: 0 }}>
        <Box 
          sx={{ 
            width: '100%',  // Full viewport width
            height: '100%', // Full viewport height
            backgroundImage: 'url(/images/background-4.jpg)', 
            backgroundSize: 'cover', 
            backgroundPosition: 'right center',
            backgroundRepeat: 'no-repeat',
          }} 
        />
      </Grid>
    </Grid>
  );
};

export default LoginView;
