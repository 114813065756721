import React, { useState, useEffect, useCallback } from "react";
import { Box, Button, CircularProgress, TextField, Typography } from "@mui/material";
import toast from 'react-hot-toast';
import { auth } from "../../shared/firebase";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { recordInvitedUser, verifyReCaptcha } from "../../shared/services";
import { analytics } from "../../shared/utils";

type InputProps = {
  onSuccess: (userId: string, isNewUser: boolean) => void;
  referrerId?: string;
}

/**
 * EmailAuth
 * Component for signing up with email and password
 * @param onSuccess (function) - Callback function for when the user is created
 * @param referrerId (string) - The referrer code
 */
const EmailAuth = ({ onSuccess, referrerId }: InputProps) => {
  // Get reCAPTCHA token
  const { executeRecaptcha } = useGoogleReCaptcha();
  // Signup state variables
  const [loading, setLoading] = useState<boolean>(false);
  const [email, setEmail] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [confirmPassword, setConfirmPassword] = useState<string>();
  const [reCaptchaToken, setReCaptchaToken] = useState<string>();
  const [hasError, setHasError] = useState<boolean>(false);

  const onReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) return;
    const token = await executeRecaptcha("signup");
    setReCaptchaToken(token);
  }, [executeRecaptcha]);

  // Hook to generate a token immediately 
  useEffect(() => {
    onReCaptchaVerify();
  }, [onReCaptchaVerify]);

  // Callback for when a user is created
  const onCreate = async () => {
    setLoading(true);
    if (!email || !password) {
      toast.error("Please enter an email and password");
      analytics.track("Web/Signup/Email: signup error", { error: "Empty email or password" });
      setLoading(false);
      return;
    } else if (password !== confirmPassword) {
      toast.error("Passwords do not match");
      analytics.track("Web/Signup/Email: signup error", { error: "Passwords do not match" });
      setLoading(false);
      return;
    }
    // Verify the reCAPTCHA token
    if (!reCaptchaToken) {
      toast.error("Something went wrong. Please try again.");
      analytics.track("Web/Signup/Email: signup error", { error: "No reCAPTCHA token generated" });
      setLoading(false);
      return;
    }
    const verified = await verifyReCaptcha(reCaptchaToken);
    if (!verified) {
      toast.error("Failed to verify reCAPTCHA. Please try again.");
      analytics.track("Web/Signup/Email: signup error", { error: "Invalid reCAPTCHA token" });
      setLoading(false);
      return;
    }
    let newUserId: string = "";
    let isNewUser: boolean = false;
    // Create the user with firebase
    try {
      const result = await createUserWithEmailAndPassword(auth, email, password);
      const user = result.user;
      isNewUser = user.metadata.creationTime === user.metadata.lastSignInTime;
      newUserId = user.uid; // Get the unique user ID
      setHasError(false);
    } catch (error: any) {
      if (error.code === "auth/email-already-in-use") {
        toast.error("An account with this email already exists.");
      } else if (error.code === "auth/invalid-email") {
        toast.error("Please enter a valid email address.");
      } else if (error.code === "auth/weak-password") {
        toast.error("Please use a stronger password.");
      } else {
        toast.error("Something went wrong. Please try again.");
      }
      analytics.track("Web/Signup/Email: signup error", { error: error });
      setHasError(true);
      setLoading(false);
      return;
    }
    // Record the invite if there is a referrer
    if (referrerId && newUserId) {
      try {
        await recordInvitedUser(referrerId, newUserId);
        analytics.track("Web/Signup/Email: record invite", { referrerId: referrerId, newUserId: newUserId });
      } catch (e) {  // non-blocking error
        console.error("Error in `recordInvitedUser`:", e);
      }
    }
    setHasError(false);
    setLoading(false);
    // Navigate to the username selection page
    onSuccess(newUserId, isNewUser);
  }

  return (
    <Box
      component="form"
      sx={{ '& .MuiTextField-root': { m: 1, width: '25ch' } }}
      noValidate
      autoComplete="off"
    >
      <Box>
        <TextField
          required
          error={hasError}
          id="outlined-error"
          label="Email"
          value={email}
          variant="filled"
          onChange={(e) => setEmail(e.target.value)}
          sx={{
            minWidth: 300,
            "& .MuiFilledInput-root": {
              backgroundColor: "#2d2d2d", // Change the background color
              color: "white", // Change the text color
            },
            "& .MuiInputLabel-root": {
              color: "white", // Change the label color
            },
            "& .MuiFilledInput-underline:before": {
              borderBottomColor: "white", // Change the underline color
            },
            "& .MuiFilledInput-underline:hover:before": {
              borderBottomColor: "yellow", // Change underline color on hover
            },
            "& .MuiFilledInput-underline:after": {
              borderBottomColor: "#5040FF", // Change underline color on focus
            },
          }}
        />
      </Box>
      <Box>
        <TextField
          required
          error={hasError}
          id="outlined-error"
          label="Password"
          type="password"
          value={password}
          variant="filled"
          onChange={(e) => setPassword(e.target.value)}
          sx={{
            minWidth: 300,
            "& .MuiFilledInput-root": {
              backgroundColor: "#2d2d2d", // Change the background color
              color: "white", // Change the text color
            },
            "& .MuiInputLabel-root": {
              color: "white", // Change the label color
            },
            "& .MuiFilledInput-underline:before": {
              borderBottomColor: "white", // Change the underline color
            },
            "& .MuiFilledInput-underline:hover:before": {
              borderBottomColor: "yellow", // Change underline color on hover
            },
            "& .MuiFilledInput-underline:after": {
              borderBottomColor: "#5040FF", // Change underline color on focus
            },
          }}
        />
      </Box>
      <Box>
        <TextField
          required
          error={hasError}
          id="outlined-error"
          label="Confirm password"
          type="password"
          value={confirmPassword}
          variant="filled"
          onChange={(e) => setConfirmPassword(e.target.value)}
          sx={{
            minWidth: 300,
            "& .MuiFilledInput-root": {
              backgroundColor: "#2d2d2d", // Change the background color
              color: "white", // Change the text color
            },
            "& .MuiInputLabel-root": {
              color: "white", // Change the label color
            },
            "& .MuiFilledInput-underline:before": {
              borderBottomColor: "white", // Change the underline color
            },
            "& .MuiFilledInput-underline:hover:before": {
              borderBottomColor: "yellow", // Change underline color on hover
            },
            "& .MuiFilledInput-underline:after": {
              borderBottomColor: "#5040FF", // Change underline color on focus
            },
          }}
        />
      </Box>
      <Box sx={{ mt: 4 }}>
        <Button 
          onClick={onCreate}
          disabled={loading}
          sx={{ 
            minWidth: 300,
            borderRadius: 50,
            backgroundColor: "#5040FF",
            color: "white",
            fontWeight: "bold",
            py: 2,
            textTransform: "none",
          }}
        >
          {loading 
            ? <CircularProgress /> 
            : <Typography sx={{ fontWeight: "bold", fontSize: 18 }}>Continue with email</Typography>
          }
        </Button>
      </Box>
    </Box>
  );
}

export default EmailAuth;