import { Box, Typography } from "@mui/material";
import type { FBClusterTagV4 } from "../../../shared/types";

type InputProps = {
  clusters: FBClusterTagV4[];
  selectedClusterId?: string;
  setSelectedClusterId: (x: string) => void;
}

/**
 * Toggle to switch between players when we do not know teams
 * @param clusters (FBClusterTagV4[])
 * @param selectedClusterId (number)
 * @param setSelectedClusterId (x: number) => void
 * @returns 
 */
const ToggleCluster = ({ clusters, selectedClusterId, setSelectedClusterId }: InputProps) => {
  const cluster = clusters.find(cluster => cluster.clusterId === selectedClusterId);
  if (!cluster) return null;
  const index = clusters.indexOf(cluster);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: "#202429",
        borderRadius: 20,
        maxWidth: 400,
        px: 4,
      }}
    >
      <Box 
        onClick={() => { if (index > 0) setSelectedClusterId(clusters[index-1].clusterId!) }}
        sx={{ pt: 1 }}
      >
        <img 
          src={index > 0 ? "/icons/arrow-left.svg" : "/icons/arrow-left-gray.svg"} 
          alt="Left arrow" 
        />
      </Box>
      <Box sx={{ borderRadius: 20, px: 6, py: 1, width: 200 }}>
        <Typography sx={{ color: "white", fontSize: 20, fontWeight: "bold" }}>
          {cluster.clusterClass === -1 ? "Untagged" : cluster.name || `Player ${index}`}
        </Typography>
      </Box>
      <Box 
        onClick={() => { if (index < (clusters.length - 1)) setSelectedClusterId(clusters[index+1].clusterId!) }}
        sx={{ pt: 1 }}
      >
        <img 
          src={index < (clusters.length - 1) ? "/icons/arrow-right.svg" : "/icons/arrow-right-gray.svg"} 
          alt="Right arrow" 
        />
      </Box>
    </Box>
  );
}

export default ToggleCluster;