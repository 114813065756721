import React, { useState, useEffect } from "react";
import { Box, Typography, Stack } from "@mui/material";
import Grid from '@mui/material/Grid2';
import { useSearchParams } from "react-router-dom";
import EmailAuth from "./signup/EmailAuth";
import GoogleAuth from "./login/GoogleAuth";
import AppleAuth from "./login/AppleAuth";
import { PRIVACY_POLICY } from "../shared/constants";
import { FBUser } from "../shared/types";
import { fetchUser } from "../shared/services";
import { useNavigate } from 'react-router-dom';
import { analytics } from "../shared/utils";

/**
 * SignupView
 * Component for signing up
 */
const SignupView = () => {
  // Get referrer from URL params
  const [searchParams] = useSearchParams();
  const referrer = searchParams.get("referrer");

  // State variable to fetch the referrer
  const [referrerUser, setReferrerUser] = useState<FBUser>();

  // Navigation
  const navigate = useNavigate();

  useEffect(() => {
    analytics.screen("Web/Signup", { referrer: referrer });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referrer]);

  /**
   * Fetch the referrer user
   */
  useEffect(() => {
    if (!referrer) return;
    fetchUser(referrer)
    .then(setReferrerUser)
    .catch((error) => {
      console.error(`Error fetching referrer user: ${error}`);
      analytics.track("Web/Signup: referrer fetch error", { error: error });
    });
  }, [referrer]);

  const onSuccess = (userId: string, isNewUser: boolean) => {
    analytics.track("Web/Signup: signup success");
    if (isNewUser) {
      navigate(`/username`);
    } else {
      navigate(`/games`);
    }
  }

  const OrDivider = () => (
    <Box sx={{ display: 'flex', flexDirection: "row", alignItems: "center", width: "100%", gap: 4 }}>
      <Box sx={{ flex: 1, height: "4px", backgroundColor: '#202429' }} />
      <Typography sx={{ fontSize: 24, color: "white", fontWeight: "bold", textAlign: "center", paddingHorizontal: 25 }}>
        OR
      </Typography>
      <Box sx={{ flex: 1, height: "4px", backgroundColor: '#202429' }} />
    </Box>
  );

  const LoginCTA = () => {
    return (
      <Typography sx={{ color: "white", textAlign: "center", fontWeight: "bold" }}>
        Already a Hooper? <a href="/login" style={{ color: "#80b5ff", textDecoration: "none", fontWeight: "bold" }}>Login here</a>
      </Typography>
    )
  }

  const ReferralBanner = () => {
    if (!referrerUser) return null;
    return (
      <Box sx={{ borderRadius: 10, backgroundColor: "#16181A", p: 2, my: 4 }}>
        <Stack direction="row" spacing={2} alignItems="center">
          {referrerUser.profilePicture && (
            <img 
              src={referrerUser.profilePicture} 
              alt="referrer" 
              width={36} 
              height={36} 
              style={{ borderRadius: "100%" }} 
            />
          )}
          <Typography sx={{ color: "white" }}>
            <b>{referrerUser.username}</b> has invited you to join
          </Typography>
        </Stack>
      </Box>
    );
  }

  return (
    <Grid container spacing={0} sx={{ height: '100vh'}}>
      <Grid size={{ xl: 6, lg: 6, md: 6, xs: 0, sm: 0 }}>
        <Box 
          sx={{ 
            width: '100%',  // Full viewport width
            height: '100%', // Full viewport height
            backgroundImage: 'url(/images/background-1.jpg)', 
            backgroundSize: 'cover', 
            backgroundPosition: 'right center',
            backgroundRepeat: 'no-repeat',
          }} 
        />
      </Grid>
      <Grid size={{ xl: 6, lg: 6, md: 6, xs: 12, sm: 12 }}>
        <Box 
          sx={{ 
            width: '100%',  // Full viewport width
            height: '100%', // Full viewport height
            backgroundColor: '#0c0c0d', 
            backgroundImage: 'url(/images/dot-pattern.svg)',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            display: 'flex',           // Use flexbox
            flexDirection: 'column',   // Stack content vertically
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <img src="/logos/logo-white.svg" alt="logo" width={150} />
          <ReferralBanner />
          <Box sx={{ mb: 6, mt: referrerUser ? 0 : 4 }}>
            <EmailAuth 
              onSuccess={onSuccess}
              referrerId={referrerUser?.userId} 
            />
          </Box>
          <Box sx={{ mb: 2 }}>
            <LoginCTA />
          </Box>
          <OrDivider />
          <Box sx={{ my: 2}}>
            <GoogleAuth onSuccess={onSuccess} />
            <AppleAuth onSuccess={onSuccess} />
          </Box>
          <Box sx={{ mt: 4 }}>
            <Typography sx={{ color: "#404040", textAlign: "center" }}>
              By signing up, you agree to our <a href={PRIVACY_POLICY} style={{ color: "#404040", textDecoration: "underline" }}>privacy policy</a>
            </Typography>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

export default SignupView;