import { Box, Stack, Typography } from "@mui/material";

/**
 * ErrorSession
 * To show when we fail to fetch the session
 */
const ErrorSession = () => {
  return (
    <Box sx={{ backgroundColor: "#16181A", borderRadius: 4, p: 4 }}>
      <Stack direction="row" spacing={1}>
        <Typography sx={{ color: "white", fontSize: 18, fontWeight: "bold" }}>
          Something went wrong.
        </Typography>
        <Typography sx={{ color: "#606060", fontSize: 18 }}>
          Please try again later. We are working on a fix!
        </Typography>
      </Stack>
    </Box>
  )
}

export default ErrorSession;