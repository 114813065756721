import { Box, Stack, Typography } from "@mui/material";

/**
 * EmptyCardGrid
 * To show when there are no cards
 */
const EmptyCardGrid = () => {
  return (
    <Box sx={{ backgroundColor: "#16181A", borderRadius: 4, p: 4 }}>
      <Stack direction="row" spacing={1}>
        <Typography sx={{ color: "white", fontSize: 18, fontWeight: "bold" }}>
          No recordings found.
        </Typography>
        <Typography sx={{ color: "#606060", fontSize: 18 }}>
          Try recording a session using the mobile app!
        </Typography>
      </Stack>
    </Box>
  )
}

export default EmptyCardGrid;