import type { FBUser } from "../../shared/types";
import { Box, Typography } from "@mui/material";

type InputProps = {
  user: FBUser;
}

const ProfileInfo = ({ user }: InputProps) => {
  return (
    <Box sx={{ my: 4}}>
      <Box sx={{ my: 2 }}>
        {user.profilePicture ? (
          <img src={user.profilePicture} alt="profile" width={100} height={100} style={{ borderRadius: 100 }} />
        ) : (
          <img src="/images/blank-profile.webp" alt="profile" width={100} height={100} style={{ borderRadius: 100 }} />
        )}
      </Box>
      <Box sx={{ backgroundColor: "#202429", borderRadius: 10, px: 2, py: 1, mb: 2, minWidth: 160 }}>
        <Typography sx={{ color: user.username ? "white" : "#606060", fontSize: user.username ? 24 : 16, fontWeight: "bold" }}>
          {user.username || "Tag not chosen"}
        </Typography>
      </Box>
      <Typography sx={{ color: "#606060", fontSize: 14 }}>
        Joined {new Date(user.createdAt*1000).toLocaleDateString("en-US", { year: "numeric", month: "short", day: "numeric" })}
      </Typography>
    </Box>
  );
};

export default ProfileInfo;
