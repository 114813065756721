import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

/**
 * Loading
 * Component for loading
 */
const LoadingOverlay = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        backgroundColor: "rgba(255, 255, 255, 0.8)",
        zIndex: 1000,
      }}
    >
      <Box 
        sx={{ 
          width: '100%',  // Full viewport width
          height: '100%', // Full viewport height
          backgroundColor: '#0c0c0d', 
          backgroundImage: 'url(/images/dot-pattern.svg)',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          display: 'flex',           // Use flexbox
          flexDirection: 'column',   // Stack content vertically
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircularProgress />
      </Box>
    </Box>
  );
};

export default LoadingOverlay;
