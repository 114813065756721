import React from "react";
import { Box, Button } from "@mui/material";
import { auth } from "../../shared/firebase";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import toast from 'react-hot-toast';
import { analytics } from "../../shared/utils";

type InputProps = {
  onSuccess: (userId: string, isNewUser: boolean) => void;
}

/**
 * GoogleAuth
 * Component for logging in with Google
 */
const GoogleAuth = ({ onSuccess }: InputProps) => {

  const onLogin = async () => {
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      const isNewUser = user.metadata.creationTime === user.metadata.lastSignInTime;
      onSuccess(user.uid, isNewUser);
      analytics.track("Web/Login/Google: login success");
    } catch (error) {
      console.error(`Error logging in: ${error}`);
      toast.error("Something went wrong. Please try again.");
      analytics.track("Web/Login/Google: login error", { error: error });
      return;
    }
  }

  return (
    <Button onClick={onLogin}>
      <Box sx={{ borderRadius: 16, backgroundColor: "#16181A", px: 8, pt: 1.8, pb: 1.2 }}>
        <img src="/icons/social-google.png" height={36} width={36} alt="Google" />
      </Box>
    </Button>
  );
};

export default GoogleAuth;
