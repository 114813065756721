import React, { useState } from "react";
import { Box, Button, CircularProgress, TextField, Typography } from "@mui/material";
import Grid from '@mui/material/Grid2';
import { useAuth } from "./AuthProvider";
import { checkUserUsername, updateUserUsername } from "../shared/services";
import type { UpdateUserUsernameRequest } from "../shared/types";
import { BannedWords } from "../shared/utils";
import { useNavigate } from 'react-router-dom';
import { analytics } from "../shared/utils";
import toast from "react-hot-toast";
import { signOut } from "firebase/auth";
import { auth } from "../shared/firebase";

const UsernameView = () => {
  const [hasError, setHasError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [username, setUsername] = useState<string>("");
  
  // Navigation
  const navigate = useNavigate();
  
  // Get the user
  const { user, setUser } = useAuth();

  // User is not logged in
  if (!user) {
    signOut(auth)
    .then(() => {
      toast.error("We had to log you out. Please sign in again.");
      console.error("User is not logged in. Forcefully logging out.");
      navigate("/login");
    });
  }
  
  // Hook on user choosing a tag
  const onChoose = async () => {
    if (!user) return;  // User is not logged in
    setLoading(true);
    if (!username) {
      toast.error("Please enter a username");
      analytics.track("Web/Signup/Email: signup error", { error: "Empty username" });
      setLoading(false);
      return;
    }
    // Check if username is valid
    const isValid = await checkUsernameValid(username);
    if (!isValid) {
      setLoading(false);
      analytics.track("Web/Signup/Email: signup error", { error: "Invalid username" });
      return;
    }
    // Update the username
    try {
      const req: UpdateUserUsernameRequest = { userId: user.userId, username: username };
      const updatedUser = await updateUserUsername(req);
      setUser({...updatedUser});  // Update the auth context with username
      analytics.track("Web/Signup/Email: signup success", { userId: user.userId, username: username });
    } catch (error) {
      console.error(`Error updating username: ${error}`);
      toast.error("Something went wrong. Please try again later.");
      analytics.track("Web/Signup/Email: username error", { error: error });
      setLoading(false);
      return;
    }
    setHasError(false);
    // Navigate to the profile page
    navigate("/games");
  }

  /**
   * Check if username input is valid
   * @param username (string)
   * @returns (boolean)
   */
  const checkUsernameValid = async (username: string | null): Promise<boolean> => {
    // Username is null
    if (!username) {
      toast.error("Please enter a player tag.");
      setHasError(true);
      return false;
    }
    username = username.toLowerCase();
    // Too short
    if (username.length < 4) {
      toast.error("Hooper tags must be at least 4 characters long.");
      setHasError(true);
      return false;
    }
    // Check no strange characters
    const alphanumericRegex = /^[a-zA-Z0-9]+$/;
    const isAlphanumeric = alphanumericRegex.test(username);
    if (!isAlphanumeric) {
      toast.error("Sorry, you cannot use this player tag. Please try again.");
      setHasError(true);
      return false;
    }
    // Check no banned words
    for (var banned of BannedWords) {
      if (username.includes(banned)) {
        toast.error("Sorry, you cannot use this Hooper tag. Please try again.");
        setHasError(true);
        return false;
      }
    }
    // Check if username is taken
    if (await checkUserUsername(username)) {
      toast.error("Sorry, this username is already taken. Got any more ideas?");
      setHasError(true);
      return false;
    }
    return true;
  }

  const getUsernameForm = () => {
    return (
      <Box
        component="form"
        sx={{ '& .MuiTextField-root': { m: 1, width: '25ch' } }}
        noValidate
        autoComplete="off"
      >
        <Box sx={{ mb: 4 }}>
          <TextField
            required
            error={hasError}
            id="outlined-error"
            label="Hooper tag"
            value={username}
            placeholder="chefcurry"
            variant="filled"
            onChange={(e) => setUsername(e.target.value)}
            sx={{
              minWidth: 300,
              "& .MuiFilledInput-root": {
                backgroundColor: "#2d2d2d", // Change the background color
                color: "white", // Change the text color
              },
              "& .MuiInputLabel-root": {
                color: "white", // Change the label color
              },
              "& .MuiFilledInput-underline:before": {
                borderBottomColor: "white", // Change the underline color
              },
              "& .MuiFilledInput-underline:hover:before": {
                borderBottomColor: "yellow", // Change underline color on hover
              },
              "& .MuiFilledInput-underline:after": {
                borderBottomColor: "#5040FF", // Change underline color on focus
              },
            }}
          />
        </Box>
        <Button 
          onClick={onChoose}
          disabled={loading}
          sx={{ 
            minWidth: 300,
            borderRadius: 50,
            backgroundColor: "#5040FF",
            color: "white",
            fontWeight: "bold",
            py: 2,
            textTransform: "none",
          }}
        >
          {loading 
            ? <CircularProgress /> 
            : <Typography sx={{ fontWeight: "bold", fontSize: 18 }}>Choose</Typography>
          }
        </Button>
      </Box>
    );
  }

  return (
    <Grid container spacing={0} sx={{ height: '100vh' }}>
      <Grid size={{ xl: 6, lg: 6, md: 6, xs: 0, sm: 0 }}>
        <Box 
          sx={{ 
            width: '100%',  // Full viewport width
            height: '100%', // Full viewport height
            backgroundImage: 'url(/images/background-3.jpg)', 
            backgroundSize: 'cover', 
            backgroundPosition: 'right center',
            backgroundRepeat: 'no-repeat',
          }} 
        />
      </Grid>
      <Grid size={{ xl: 6, lg: 6, md: 6, xs: 12, sm: 12 }}>
        <Box 
          sx={{ 
            width: '100%',  // Full viewport width
            height: '100%', // Full viewport height
            backgroundColor: '#0c0c0d', 
            backgroundImage: 'url(/images/dot-pattern.svg)',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            display: 'flex',           // Use flexbox
            flexDirection: 'column',   // Stack content vertically
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <img src="/logos/logo-white.svg" alt="logo" width={150} />
          <Box sx={{ mt: 4 }}>
            {getUsernameForm()}
          </Box>
          <Box sx={{ mt: 4, mx: 4 }}>
            <Typography sx={{ color: "#404040", textAlign: "center", fontWeight: "bold" }}>
              Your Hooper tag is your basketball nickname.
            </Typography>
            <Typography sx={{ color: "#404040", textAlign: "center" }}>
              It is used to identify you, showcase your stats, and connect with other players. 
            </Typography>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

export default UsernameView;