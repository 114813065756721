import React from "react";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import ReactPlayer from 'react-player';
import type { TagShotHighlight, TaggedPlayer } from "../../shared/types";
import { formatTime, cutoffText } from "../../shared/utils";

type InputProps = {
  item: TagShotHighlight;
  player?: TaggedPlayer;
}

/**
 * A card that displays a highlight
 * @param item (FBHighlightV4)
 * @param player (TaggedPlayer)
 */
const HighlightCard = ({ item, player }: InputProps) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Box 
      style={{ position: "relative", width: isSmallScreen ? 150 : 300, height: isSmallScreen ? 250 : 500 }}
      sx={{ borderRadius: 2, overflow: "hidden" }}
    >
      <ReactPlayer 
        url={item.highlight.video}
        light={item.highlight.preview}
        controls={true}
        style={{ position: "absolute", top: 0, left: 0 }}
        width="100%"
        height="100%"
      />
      <Box 
        style={{ 
          position: "absolute", 
          bottom: 20, 
          left: 20, 
          width: "100%", 
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 10,
        }}
      >
        <img
          src={(player && player.profilePicture) ? player.profilePicture : "/images/blank-profile.webp"}
          alt="player profile"
          style={{ width: isSmallScreen ? 25 : 40, height: isSmallScreen ? 25 : 40, borderRadius: "50%" }}
        />
        <Typography style={{ color: "white", fontSize: isSmallScreen ? 14 : 18, fontWeight: "bold" }}>
          {player ? (player.name ? `${cutoffText(player.name, isSmallScreen ? 7 : 20)}` : `Player ${player.clusterClass + 1}`) : "Unclaimed"}
        </Typography>
      </Box>
      <Box 
        style={{ 
          position: "absolute", 
          top: 20, 
          left: 20, 
          width: "100%", 
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 8,
        }}
      >
        <img src="/icons/clock-light.svg" alt="clock" style={{ width: 16, height: 16 }} />
        <Typography style={{ color: "white", fontSize: isSmallScreen ? 14 : 18}}>
          {formatTime((item.shot.startTs! + item.shot.endTs!) / 2)}
        </Typography>
      </Box>
    </Box>
  );
}

export default HighlightCard;

