import React from "react";
import { Box, Typography } from "@mui/material";

type InputProps = {
  teamNames: string[];
  selectedTeamId: number;
  setSelectedTeamId: (x: number) => void;
}

const ToggleTeam = ({ teamNames, selectedTeamId, setSelectedTeamId }: InputProps) => {
  const width = window.innerWidth;
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        backgroundColor: "#202429",
        borderRadius: 20,
      }}
    >
      <Box 
        sx={{ 
          backgroundColor: (selectedTeamId === 1) ? "white" : "#202429", 
          px: 4,
          py: 1,
          borderRadius: 20,
          width: width < 500 ? 160 : 200,
        }}
        onClick={() => setSelectedTeamId(1)}
      >
        <Typography 
          sx={{ 
            color: (selectedTeamId === 1) ? "#202429" : "white", 
            fontSize: 20,
            fontWeight: (selectedTeamId === 1) ? "bold" : "normal",
          }}
        >
          {teamNames[0]}
        </Typography>
      </Box>
      <Box 
        sx={{ 
          backgroundColor: (selectedTeamId === 2) ? "white" : "#202429", 
          px: 4,
          py: 1,
          borderRadius: 20,
          width: width < 500 ? 160 : 200,
        }}
        onClick={() => setSelectedTeamId(2)}
      >
        <Typography 
          sx={{ 
            color: (selectedTeamId === 2) ? "#202429" : "white", 
            fontSize: 20,
            fontWeight: (selectedTeamId === 2) ? "bold" : "normal",
          }}
        >
          {teamNames[1]}
        </Typography>
      </Box>
    </Box>
  );
}

export default ToggleTeam;