import React, { createContext, useContext, useState, useEffect } from "react";
import { auth } from "../shared/firebase";
import type { FBUser } from "../shared/types";
import { checkUser, fetchUser, createUser } from "../shared/services";
import { onAuthStateChanged } from "firebase/auth";
import type { CreateUserRequest } from "../shared/types";
import { USER_TYPE_USER, SUBSCRIPTION_TYPE_BETA } from "../shared/constants";

const AuthContext = createContext<{ 
  user: FBUser | undefined; 
  setUser: (user: FBUser) => void;
  loading: boolean, 
}>({ user: undefined, loading: true, setUser: () => {} });

/**
 * Provider for the user context
 * @param children - The children to render
 * @returns The user context provider
 */
export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  // State to store the user
  const [user, setUser] = useState<FBUser>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, onAuth);
    return () => unsubscribe();
  }, []);

  const onAuth = async (fbUser: any) => {
    setLoading(true); // Mark loading as true
    try {
      if (fbUser) {
        let user: FBUser;
        let userId: string = fbUser.uid;
        let email: string = fbUser.email;
        const userExists = await checkUser(userId);
        if (userExists) {
          // If it is an existing user, this is just the user signing in
          user = await fetchUser(fbUser.uid);
        } else {
          // Create POST request to create user on backend
          let body: CreateUserRequest = {
            type: USER_TYPE_USER,
            subscriptionType: SUBSCRIPTION_TYPE_BETA,
          }
          if (email) body.email = email;
          user = await createUser(body);
        }
        setUser(user);
      } else {
        // Calls this when logging out. Set user to `null`
        setUser(undefined);
      }
    } catch (error) {
      // Something bad happened - silently fail for now
      console.error(error);
    } finally {
      setLoading(false); // Mark loading as complete
    }
  }

  return (
    <AuthContext.Provider value={{ user, setUser, loading }}>
      {children}
    </AuthContext.Provider>
  );
};

/**
 * Hook to access the user context
 * @returns The user context
 */
export const useAuth = () => {
  return useContext(AuthContext);
};