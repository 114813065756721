import React, { useState, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { 
  fetchUser,
  fetchSession, 
  fetchSessionHighlights,
  fetchSessionTags,
  fetchSessionClusters,
  fetchSessionShots,
  fetchTaggedUsers,
} from "../shared/services";
import type { 
  FBUser,
  FBSession, 
  FBHighlightV4, 
  FBTagV4, 
  FBClusterTagV4,
  FBShotV4,
  ClusterActionsV4,
  TaggedPlayer,
  TagShotHighlight,
} from "../shared/types";
import MobilePopup from "./MobilePopup";
import { useAuth } from "./AuthProvider";
import { auth } from "../shared/firebase";
import { signOut } from "firebase/auth";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import toast from 'react-hot-toast';
import ShotChart from "./session/ShotChart";
import LoadingOverlay from "./LoadingOverlay";
import ErrorSession from "./session/ErrorSession";
import WebWarning from "./session/WebWarning";
import Summary from "./session/Summary";
import Footage from "./session/Footage";
import Highlights from "./session/Highlights";
import BoxScores from "./session/BoxScores";
import { GAME_MODE, ACTION_SHOT, ACTION_REBOUND, ACTION_ASSIST } from "../shared/constants";
import { useNavigate } from "react-router-dom";
import { analytics } from "../shared/utils";
import { 
  copyShotPropsToTagsV4, 
  aggregateActionsByClusterV4,
  findValidRebounds,
  findValidAssists,
  isShotIn,
} from "../shared/utils";

const SessionView = () => {
  // Fetch the session ID from the route
  const { id } = useParams();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [isLessThan400, setIsLessThan400] = useState<boolean>(window.innerWidth < 400);
  const [isLessThan500, setIsLessThan500] = useState<boolean>(window.innerWidth < 500);
  // Fetch the session
  const [session, setSession] = useState<FBSession>();
  const [owner, setOwner] = useState<FBUser>();
  const [highlights, setHighlights] = useState<FBHighlightV4[]>([]);
  const [tags, setTags] = useState<FBTagV4[]>([]);
  const [clusters, setClusters] = useState<FBClusterTagV4[]>([]);
  const [shots, setShots] = useState<FBShotV4[]>([]);
  const [taggedUsers, setTaggedUsers] = useState<FBUser[]>([]);
  const [loadingSession, setLoadingSession] = useState<boolean>(true);
  const [loadingHighlights, setLoadingHighlights] = useState<boolean>(true);
  const [loadingTags, setLoadingTags] = useState<boolean>(true);
  const [loadingTaggedUsers, setLoadingTaggedUsers] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  // Fetch the user from the context
  const { user } = useAuth();
  // State for the mobile popup
  const [openPopup, setOpenPopup] = useState<boolean>(!user);
  // Navigate to the home page
  const navigate = useNavigate();

  useEffect(() => {
    onFetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setIsLessThan400(window.innerWidth < 400);
      setIsLessThan500(window.innerWidth < 500);
    });
  }, []);

  // Entry point to fetch all the data
  const onFetchData = async () => {
    if (!id) return;
    try {
      fetchSessionCallback();
      fetchHighlightsCallback();
      fetchTagsCallback();
      fetchTaggedUsersCallback();
      setIsError(false);
    } catch (error) {
      toast.error("Something went wrong. Please try again later.");
      setIsError(true);
    }
  }

  const fetchSessionCallback = async () => {
    if (!id) return;
    setLoadingSession(true);
    try {
      const session = await fetchSession(id)
      if (session) {
        setSession(session);
        const owner = await fetchUser(session.userId);
        if (owner) {
          setOwner(owner);
        } else {
          setOwner(undefined);
        }
      } else {
        setSession(undefined);
      }
    } catch (error) {
      analytics.track("Web/Session: fetchSession error", { error: error });
    } finally {
      setLoadingSession(false);
    }
  }

  const fetchHighlightsCallback = async () => {
    if (!id) return;
    setLoadingHighlights(true);
    try {
      const highlights = await fetchSessionHighlights(id);
      if (highlights) {
        setHighlights([...highlights]);
      } else {
        setHighlights([]);
      }
    } catch (error) {
      analytics.track("Web/Session: fetchHighlights error", { error: error });
    } finally {
      setLoadingHighlights(false);
    }
  }

  const fetchTagsCallback = async () => {
    if (!id) return;
    setLoadingTags(true);
    try {
      let [tags, clusters, shots] = await Promise.all([
        fetchSessionTags(id),
        fetchSessionClusters(id),
        fetchSessionShots(id),
      ]);
      if (tags) {
        setTags([...copyShotPropsToTagsV4(tags, shots)]);
      } else {
        setTags([]);
      }
      if (clusters) {
        setClusters([...clusters]);
      } else {
        setClusters([]);
      }
      if (shots) {
        setShots([...shots]);
      } else {
        setShots([]);
      }
    } catch (error) {
      analytics.track("Web/Session: fetchTags error", { error: error });
    } finally {
      setLoadingTags(false);
    }
  }

  const fetchTaggedUsersCallback = async () => {
    if (!id) return;
    setLoadingTaggedUsers(true);
    try {
      const users = await fetchTaggedUsers(id);
      if (users) {
        setTaggedUsers([...users]);
      } else {
        setTaggedUsers([]);
      }
    } catch (error) {
      analytics.track("Web/Session: fetchClusterTaggedUsers error", { error: error });
    } finally {
      setLoadingTaggedUsers(false);
    }
  }

  // Cluster shots
  const clusterShots = useMemo(() => aggregateActionsByClusterV4(
    clusters.filter(cluster => !cluster.ignore), 
    tags.filter(tag => !tag.ignore), 
    shots.filter(shot => !shot.ignore), 
    ACTION_SHOT,
  ), [clusters, tags, shots]);
  // Cluster rebounds
  const clusterRebounds: ClusterActionsV4[] = useMemo(() => findValidRebounds(
    aggregateActionsByClusterV4(
      clusters.filter(cluster => !cluster.ignore), 
      tags.filter(tag => !tag.ignore), 
      shots.filter(shot => !shot.ignore), 
      ACTION_REBOUND
    ),
  ), [clusters, tags, shots]);
  // Cluster assists
  const clusterAssists: ClusterActionsV4[] = useMemo(() => findValidAssists(
    aggregateActionsByClusterV4(
      clusters.filter(cluster => !cluster.ignore), 
      tags.filter(tag => !tag.ignore), 
      shots.filter(shot => !shot.ignore), 
      ACTION_ASSIST,
    ), 
    clusters.filter(cluster => !cluster.ignore),
  ), [clusters, tags, shots]);

  useEffect(() => {
    analytics.screen("Web/Session", { sessionId: id, userId: user?.userId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, user]);

  /**
   * Get players from clusters
   */
   const getTaggedPlayers = (): TaggedPlayer[] => {
    const players: TaggedPlayer[] = clusters
      .filter(cluster => cluster.clusterClass !== -1) 
      .filter(cluster => !cluster.ignore)
      .map(cluster => {
        let profilePicture = undefined;
        if (cluster.userId) {
          const user = taggedUsers.find(user => user.userId === cluster.userId);
          profilePicture = user?.profilePicture;
        }
        return {
          name: cluster.name!,
          userId: cluster.userId,
          clusterId: cluster.clusterId!,
          clusterClass: cluster.clusterClass!,
          profilePicture: profilePicture,
        };
      });
    return players;
  }

  // Get tagged players
  const taggedPlayers = getTaggedPlayers();

  // Get the dataset of tagged shots and highlights
  const dataset = tags
  .filter(tag => !tag.ignore)
  .map(tag => {
    const shot = shots.find(shot => tag.shotId === shot.shotId)!;
    const highlight = highlights.find(highlight => highlight.shotId === shot.shotId)!;
    const out: TagShotHighlight = { tag, shot, highlight };
    return out;
  })
  // Filter out ignored shots and highlights
  .filter(row => !row.shot.ignore && row.highlight && row.highlight.visible && row.highlight.visible > 0)
  .filter(row => (
    // Remove any tags of rebounds of made shots
    !((row.tag.actionId === ACTION_REBOUND) && (isShotIn(row.shot))) &&
    // Remove any tags of assists of missed shots
    !((row.tag.actionId === ACTION_ASSIST) && (!isShotIn(row.shot)))
  ))
  .sort((a, b) => a.shot.startTs! - b.shot.startTs!);


  // Show loading overlay
  const loading = loadingSession || loadingHighlights || loadingTags || loadingTaggedUsers ||!session;
  if (loading) return <LoadingOverlay />;
  // Show Error overlay
  if (isError) return <ErrorSession />;

  const LogoOverlay = () => {
    return (
      <Box 
        sx={{ position: 'absolute', top: 40, left: 40}}
        onClick={() => navigate("/")}
      >
        <img src="/logos/logo-white.svg" alt="logo" width={100} />
      </Box>
    );
  }

  const HomeButton = () => {
    if (!user || isLessThan400) return null;
    return (
      <Box sx={{ position: 'absolute', top: 32, right: 150 }}>
        <Box 
          onClick={() => navigate("/")} 
          sx={{ 
            cursor: "pointer",
            backgroundColor: "#202429",
            py: 1,
            px: 2,
            borderRadius: 10,
          }}
        >
          <Typography sx={{ color: "white", fontWeight: "bold" }}>
            Profile
          </Typography>
        </Box>
      </Box>
    );
  }

  const DownloadCta = () => {
    if (user || isLessThan500) return null;
    return (
      <Box sx={{ position: 'absolute', top: 32, right: 150 }}>
        <Box 
          onClick={() => setOpenPopup(true)} 
          sx={{ 
            cursor: "pointer",
            backgroundColor: "#5040FF",
            py: 0.9,
            px: 2,
            borderRadius: 10,
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          <img src="/logo192.png" alt="logo" width={20} />
          <Typography sx={{ color: "white", fontWeight: "bold" }}>
            Download
          </Typography>
        </Box>
      </Box>
    );
  }

  const DownloadBanner = () => {
    if (user || !isLessThan500) return null;
    return (
      <Box 
        onClick={() => setOpenPopup(true)} 
        sx={{ 
          cursor: "pointer",
          backgroundColor: "#5040FF",
          py: 1,
          px: 2,
          borderRadius: 1,
          width: "100%",
          mb: 4,
          display: "flex",
          alignItems: "center",
          gap: 2,
        }}
      >
        <img src="/logo192.png" alt="logo" width={32} />
        <Typography sx={{ color: "white", fontWeight: "bold" }}>
          Download the mobile app
        </Typography>
      </Box>
    );
  }

  const LoginButton = () => {
    if (user) return null;
    return (
      <Box sx={{ position: 'absolute', top: 32, right: 40 }}>
        <Box 
          onClick={() => navigate("/login")} 
          sx={{ 
            cursor: "pointer",
            backgroundColor: "#202429",
            py: 1,
            px: 2,
            borderRadius: 10,
          }}
        >
          <Typography sx={{ color: "white", fontWeight: "bold" }}>
            Login
          </Typography>
        </Box>
      </Box>
    );
  }
  
  const LogoutButton = () => {
    if (!user) return null;
    return (
      <Box sx={{ position: 'absolute', top: 32, right: 40 }}>
        <Box 
          onClick={() => signOut(auth)} 
          sx={{ 
            cursor: "pointer",
            backgroundColor: "#202429",
            py: 1,
            px: 2,
            borderRadius: 10,
          }}
        >
          <Typography sx={{ color: "white", fontWeight: "bold" }}>
            Logout
          </Typography>
        </Box>
      </Box>
    );
  }

  const Footer = () => {
    return (
      <Box sx={{ mb: 4 }}>
        <Typography sx={{ textAlign: "center", color: "#404040" }}>
          Copyright © 2024 Built Different AI Inc. All rights reserved.
        </Typography>
      </Box>
    );
  }

  // Check if there are any unassigned clusters
  const unassignedClusters = clusters.filter(cluster => cluster.teamId !== 1 && cluster.teamId !== 2); 
  const unassignedIds = unassignedClusters.map(cluster => cluster.clusterId);
  const numUnassigned = unassignedClusters.length;

  return (
    <Box sx={{ minHeight: '100vh', flexDirection: 'column', display: 'flex' }}>
      <LogoOverlay />
      <HomeButton />
      <DownloadCta />
      <LoginButton />
      <LogoutButton />
      <MobilePopup visible={openPopup} setVisible={setOpenPopup} />
      <Box 
        sx={{ 
          flex: 1,
          width: '100%',  // Full viewport width
          height: '100%', // Full viewport height
          backgroundColor: '#0c0c0d', 
          backgroundImage: 'url(/images/dot-pattern.svg)',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          display: 'flex',           // Use flexbox
          flexDirection: 'column',   // Stack content vertically
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box sx={{ width: '100%', maxWidth: 1000, justifyContent: 'center' }}>
          <Box sx={{ mx: 2 }}>
            <Box style={{marginTop: 120}} />
            <DownloadBanner />
            <WebWarning />
            <Box sx={{ mt: 4 }}>
              <Summary 
                session={session} 
                clusters={clusters} 
                shots={clusterShots} 
                owner={owner}
              />
            </Box>
            <Box sx={{ mt: 4 }}>
              <Typography sx={{ textAlign: "left", fontSize: isSmallScreen ? 18 : 24, color: "white", fontWeight: "bold" }} gutterBottom>
                {session.sessionMode === GAME_MODE ? "Game footage" : "Shootaround footage"}
              </Typography>
              <Footage session={session} />
            </Box>
            <Box sx={{ mt: 4 }}>
              <Typography sx={{ textAlign: "left", fontSize: isSmallScreen ? 18 : 24, color: "white", fontWeight: "bold" }} gutterBottom>
                Player clips
              </Typography>
              <Highlights 
                // Only show shots
                dataset={dataset.filter(item => item.tag.actionId === ACTION_SHOT)} 
                players={taggedPlayers} 
              />
            </Box>
            <Box sx={{ mt: 4 }}>
              <Box sx={{ mt: 4 }}>
                {!isSmallScreen && (
                  <Box sx={{ mb: 2 }}>
                    <Typography sx={{ textAlign: "left", fontSize: isSmallScreen ? 18 : 24, color: "white", fontWeight: "bold" }} gutterBottom>
                      Box scores
                    </Typography>
                  </Box>
                )}
                {
                  (session.sessionMode === GAME_MODE && session.hasTeams) ? (
                    <Box>
                      <BoxScores 
                        session={session} 
                        clusterTags={clusters.filter(cluster => !cluster.ignore)}
                        clusterShots={clusterShots}
                        clusterRebounds={clusterRebounds}
                        clusterAssists={clusterAssists}
                        taggedUsers={taggedUsers}
                        teamId={1}
                        teamName={session.teamNames ? session.teamNames[0] : "Team 1"}
                      />
                      <BoxScores 
                        session={session} 
                        clusterTags={clusters.filter(cluster => !cluster.ignore)}
                        clusterShots={clusterShots}
                        clusterRebounds={clusterRebounds}
                        clusterAssists={clusterAssists}
                        taggedUsers={taggedUsers}
                        teamId={2}
                        teamName={session.teamNames ? session.teamNames[1] : "Team 2"}
                      />
                      {numUnassigned > 0 && (
                        <BoxScores
                          session={session}
                          clusterTags={unassignedClusters}
                          clusterShots={clusterShots.filter(shot => unassignedIds.includes(shot.clusterId))}
                          clusterRebounds={clusterRebounds.filter(rebound => unassignedIds.includes(rebound.clusterId))}
                          clusterAssists={clusterAssists.filter(assist => unassignedIds.includes(assist.clusterId))}
                          taggedUsers={taggedUsers}
                          teamName="Unassigned"
                        />
                      )}
                    </Box>
                  ) : (
                    <BoxScores 
                      session={session} 
                      clusterTags={clusters.filter(cluster => !cluster.ignore)}
                      clusterShots={clusterShots}
                      clusterRebounds={clusterRebounds}
                      clusterAssists={clusterAssists}
                      taggedUsers={taggedUsers}
                    />
                  )
                }
              </Box>
              <Box sx={{ mt: 4 }}>
                <Box sx={{ mb: 2 }}>
                  <Typography sx={{ textAlign: "left", fontSize: isSmallScreen ? 18 : 24, color: "white", fontWeight: "bold" }} gutterBottom>
                    Shot charts
                  </Typography>
                </Box>
                <Box>
                  <ShotChart 
                    session={session}
                    clusterTags={clusters.filter(cluster => !cluster.ignore)}
                    clusterShots={clusterShots}
                  />
                </Box>
              </Box>
            </Box>
            <Box sx={{ mt: 8}}>
              <Footer />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SessionView;
