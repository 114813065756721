import React, { useEffect, useState } from "react";
import type { PlayerShot, ShotChartZones, ShotChartRegions } from "../../../shared/types";
import { Box } from "@mui/material";
import Grid from "@mui/material/Grid2";
import ShotChartZoneMap from "./ShotChartZoneMap";
import ShotChartRegionMap from "./ShotChartRegionMap";
import { 
  BLOCK_LEFT_COORDINATES,
  ELBOW_LEFT_COORDINATES,
  PAINT_UPPER_RIGHT_COORDINATES,
  PAINT_UPPER_LEFT_COORDINATES,
  PAINT_BOTTOM_RIGHT_COORDINATES,
  PAINT_BOTTOM_LEFT_COORDINATES,
  KEY_ZONE_COORDINATES,
  BLOCK_RIGHT_COORDINATES,
  ELBOW_RIGHT_COORDINATES,
  WING_LEFT_COORDINATES,
  MIDDLE_THREE_POINT_COORDINATES,
  WING_RIGHT_COORDINATES,
  CORNER_LEFT_COORDINATES,
  CORNER_RIGHT_COORDINATES,
  PAINT_REGION_COORDINATES,
  TWO_POINT_REGION_COORDINATES,
  THREE_POINT_REGION_COORDINATES,
  SHOT_CHART_WIDTH,
  SHOT_CHART_HEIGHT,
} from "../../../shared/constants";
import classifyPoint from "robust-point-in-polygon";

type Point = [number, number];
type Zone = {
  name: string;
  coordinates: Point[];
};
type ZoneName = keyof ShotChartZones;
type ShotZone = {
  shot: PlayerShot; 
  zone?: ZoneName;
};
type Region = {
  name: string;
  coordinates: Point[];
};
type RegionName = keyof ShotChartRegions;
type ShotRegion = {
  shot: PlayerShot;
  region?: RegionName;
}

const zones: Zone[] = [
  { name: "BLOCK_LEFT", coordinates: BLOCK_LEFT_COORDINATES },
  { name: "ELBOW_LEFT", coordinates: ELBOW_LEFT_COORDINATES },
  { name: "PAINT_UPPER_RIGHT", coordinates: PAINT_UPPER_RIGHT_COORDINATES },
  { name: "PAINT_UPPER_LEFT", coordinates: PAINT_UPPER_LEFT_COORDINATES },
  { name: "PAINT_BOTTOM_RIGHT", coordinates: PAINT_BOTTOM_RIGHT_COORDINATES },
  { name: "PAINT_BOTTOM_LEFT", coordinates: PAINT_BOTTOM_LEFT_COORDINATES },
  { name: "KEY_ZONE", coordinates: KEY_ZONE_COORDINATES },
  { name: "BLOCK_RIGHT", coordinates: BLOCK_RIGHT_COORDINATES },
  { name: "ELBOW_RIGHT", coordinates: ELBOW_RIGHT_COORDINATES },
  { name: "WING_LEFT", coordinates: WING_LEFT_COORDINATES },
  { name: "MIDDLE_THREE_POINT", coordinates: MIDDLE_THREE_POINT_COORDINATES },
  { name: "WING_RIGHT", coordinates: WING_RIGHT_COORDINATES },
  { name: "CORNER_LEFT", coordinates: CORNER_LEFT_COORDINATES },
  { name: "CORNER_RIGHT", coordinates: CORNER_RIGHT_COORDINATES }
];
const regions: Region[] = [
  { name: "PAINT", coordinates: PAINT_REGION_COORDINATES },
  { name: "TWO_POINT", coordinates: TWO_POINT_REGION_COORDINATES },
  { name: "THREE_POINT", coordinates: THREE_POINT_REGION_COORDINATES },
]

type InputProps = {
  playerShots: PlayerShot[];
}

const zeroStats = {
  makes: 0,
  attempts: 0,
  misses: 0,
  percentage: "0%"
}

const defaultShotChartZones: ShotChartZones = {
  BLOCK_LEFT: zeroStats,
  ELBOW_LEFT: zeroStats,
  PAINT_UPPER_RIGHT: zeroStats,
  PAINT_UPPER_LEFT: zeroStats,
  PAINT_BOTTOM_RIGHT: zeroStats,
  PAINT_BOTTOM_LEFT: zeroStats,
  KEY_ZONE: zeroStats,
  BLOCK_RIGHT: zeroStats,
  ELBOW_RIGHT: zeroStats,
  WING_LEFT: zeroStats,
  MIDDLE_THREE_POINT: zeroStats,
  WING_RIGHT: zeroStats,
  CORNER_LEFT: zeroStats,
  CORNER_RIGHT: zeroStats,
};
const defaultShotChartRegions: ShotChartRegions = {
  PAINT: zeroStats,
  TWO_POINT: zeroStats,
  THREE_POINT: zeroStats,
}

/**
 * ShotCartGrid - Render shot chart
 * @param playerShots PlayerShot[]
 */
const ShotCartGrid = ({ playerShots }: InputProps) => {
  const [shotChartZones, setShotChartZones] = useState<ShotChartZones>(defaultShotChartZones);
  const [shotChartRegions, setShotChartRegions] = useState<ShotChartRegions>(defaultShotChartRegions);

  useEffect(() => {
    processPlayerShotsByZone();
    processPlayerShotsByRegion();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playerShots]); 

  // Maps each shot into a zone - we do this by polygon membership
  const classifyShotsIntoZones = (playerShots: PlayerShot[]): ShotZone[] => {
    return playerShots.map(shot => {
      if (!shot.location || shot.location.length !== 2 || shot.ignore) {
        // Ensure shot.location is defined and has two elements
        return { shot, zone: undefined };
      }
      // Note we still use keypoints for the old map to check this stuff
      const adjustedLocation: [number, number] = [
        (shot.location[0] * SHOT_CHART_WIDTH), 
        (shot.location[1] * SHOT_CHART_HEIGHT),
      ];
      const twoPointerZones = ["BLOCK_LEFT", "ELBOW_LEFT", "PAINT_UPPER_RIGHT", "PAINT_UPPER_LEFT", 
                               "PAINT_BOTTOM_RIGHT", "PAINT_BOTTOM_LEFT", "KEY_ZONE", "BLOCK_RIGHT", 
                               "ELBOW_RIGHT"];
      const threePointerZones = ["CORNER_LEFT", "CORNER_RIGHT", "MIDDLE_THREE_POINT", "WING_LEFT", 
                                 "WING_RIGHT"];
      const relevantZones = twoPointerZones.concat(threePointerZones);
      let closestZone: Zone | undefined = undefined;
      // Loop through relevant zones and find intersections:
      for (let i = 0; i < relevantZones.length; i++) {
        const zone: Zone = zones.find(zone => zone.name === relevantZones[i])!;
        const point: [number, number] = adjustedLocation;
        const membership = classifyPoint(zone.coordinates, point);
        if (membership !== 1) {
          closestZone = zone;
          break;
        }
      }
      // If the shot has `isTwoPointShot=True` but its location is a three pointer, then we know
      // that user overrode the shot type. We must adjust this zone accordingly.
      if (closestZone && shot.isTwoPointShot && threePointerZones.includes(closestZone.name)) {
        if (closestZone.name === "CORNER_RIGHT") {
          closestZone = zones.find(zone => zone.name === "BLOCK_RIGHT")!;
        } else if (closestZone.name === "WING_RIGHT") {
          closestZone = zones.find(zone => zone.name === "ELBOW_RIGHT")!;
        } else if (closestZone.name === "MIDDLE_THREE_POINT") {
          closestZone = zones.find(zone => zone.name === "KEY_ZONE")!;
        } else if (closestZone.name === "WING_LEFT") {
          closestZone = zones.find(zone => zone.name === "BLOCK_LEFT")!;  
        } else if (closestZone.name === "CORNER_LEFT") {
          closestZone = zones.find(zone => zone.name === "ELBOW_LEFT")!;
        }
      }
      // If the shot `isTwoPointShot=False` but its location is not a two pointer, then we know
      // that user overrode the shot type. We must adjust this zone accordingly.
      if (closestZone && !shot.isTwoPointShot && twoPointerZones.includes(closestZone.name)) {
        if (closestZone.name === "BLOCK_RIGHT") {
          closestZone = zones.find(zone => zone.name === "CORNER_RIGHT")!;
        } else if (closestZone.name === "ELBOW_RIGHT") {
          closestZone = zones.find(zone => zone.name === "WING_RIGHT")!;
        } else if (closestZone.name === "PAINT_UPPER_RIGHT") {
          closestZone = zones.find(zone => zone.name === "WING_RIGHT")!;
        } else if (closestZone.name === "PAINT_BOTTOM_RIGHT") {
          closestZone = zones.find(zone => zone.name === "CORNER_RIGHT")!;
        } else if (closestZone.name === "KEY_ZONE") {
          closestZone = zones.find(zone => zone.name === "MIDDLE_THREE_POINT")!;
        } else if (closestZone.name === "BLOCK_LEFT") {
          closestZone = zones.find(zone => zone.name === "CORNER_LEFT")!;
        } else if (closestZone.name === "ELBOW_LEFT") {
          closestZone = zones.find(zone => zone.name === "WING_LEFT")!;
        } else if (closestZone.name === "PAINT_UPPER_LEFT") {
          closestZone = zones.find(zone => zone.name === "WING_LEFT")!;
        } else if (closestZone.name === "PAINT_BOTTOM_LEFT") {
          closestZone = zones.find(zone => zone.name === "CORNER_LEFT")!;
        }
      }
      return { 
        shot, 
        zone: closestZone ? closestZone.name as ZoneName : undefined 
      };
    });
  };    

  const classifyShotsIntoRegions = (playerShots: PlayerShot[]): ShotRegion[] => {
    return playerShots.map(shot => {
      if (!shot.location || shot.location.length !== 2 || shot.ignore) {
        // Ensure shot.location is defined and has two elements
        return { shot, zone: undefined };
      }
      // Note we still use keypoints for the old map to check this stuff
      const adjustedLocation: [number, number] = [
        (shot.location[0] * SHOT_CHART_WIDTH), 
        (shot.location[1] * SHOT_CHART_HEIGHT),
      ];
      const relevantRegions = ["PAINT", "TWO_POINT", "THREE_POINT"];
      let closestRegion: Region | undefined = undefined;
      // Loop through relevant zones and find intersections:
      for (let i = 0; i < relevantRegions.length; i++) {
        const region: Region = regions.find(region => region.name === relevantRegions[i])!;
        const point: [number, number] = adjustedLocation;
        const membership = classifyPoint(region.coordinates, point);
        if (membership !== 1) {
          closestRegion = region;
          break;
        }
      }
      // If the shot has `isTwoPointShot=True` but its location is a three pointer, then we know
      // that user overrode the shot type. We must adjust this zone accordingly.
      if (closestRegion && shot.isTwoPointShot && closestRegion.name === "THREE_POINT") {
        closestRegion = regions.find(region => region.name === "TWO_POINT")!;
      }
      // If the shot `isTwoPointShot=False` but its location is not a two pointer, then we know
      // that user overrode the shot type. We must adjust this zone accordingly.
      if (closestRegion && !shot.isTwoPointShot && closestRegion.name !== "THREE_POINT") {
        closestRegion = regions.find(region => region.name === "THREE_POINT")!;
      }
      return { 
        shot, 
        region: closestRegion ? closestRegion.name as RegionName : undefined 
      };
    });
  }

  // Computes the percentages for each zone
  const processPlayerShotsByZone = () => {
    const classifiedShots = classifyShotsIntoZones(playerShots);
    const updatedZones = { ...defaultShotChartZones };
    for (const { shot, zone } of classifiedShots) {
      if (zone && updatedZones[zone]) {
        const updatedZone = { ...updatedZones[zone] };
        updatedZone.attempts += 1;
        if (shot.status === 1) {
          updatedZone.makes += 1;
        } else {
          updatedZone.misses += 1;
        }
        updatedZone.percentage = ((updatedZone.makes / updatedZone.attempts) * 100).toFixed(0)+"%";
        updatedZones[zone] = updatedZone;
      }
    }
    setShotChartZones(updatedZones);
  };

  // Computes the percentages for each region
  const processPlayerShotsByRegion = () => {
    const classifiedShots = classifyShotsIntoRegions(playerShots);
    const updatedRegions = { ...defaultShotChartRegions };
    for (const { shot, region } of classifiedShots) {
      if (region && updatedRegions[region]) {
        const updatedRegion = { ...updatedRegions[region] };
        updatedRegion.attempts += 1;
        if (shot.status === 1) {
          updatedRegion.makes += 1;
        } else {
          updatedRegion.misses += 1;
        }
        updatedRegion.percentage = ((updatedRegion.makes / updatedRegion.attempts) * 100).toFixed(0)+"%";
        updatedRegions[region] = updatedRegion;
      }
    }
    setShotChartRegions(updatedRegions);
  }
  
  return (
    <Box key="profile-shot-chart-view">
      <Grid container spacing={4}>
        <Grid size={{ xs: 12, sm: 6 }}>
          <Box
            sx={{
              borderRadius: 2,
              backgroundColor: "#16181A",
              justifyContent: 'center',
              alignItems: "center",
              margin: "0 auto",
              py: 4,
              maxWidth: 343,
              maxHeight: 294,
            }}
          >
            <ShotChartZoneMap shotChartZones={shotChartZones} />
          </Box>
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }}>
          <Box
            sx={{
              borderRadius: 2,
              backgroundColor: "#16181A",
              justifyContent:'center',
              alignItems: "center",
              margin: "0 auto",
              py: 4,
              maxWidth: 343,
              maxHeight: 294,
            }}
          >
            <ShotChartRegionMap shotChartRegions={shotChartRegions} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default ShotCartGrid;

