import React from "react";
import { Box} from "@mui/material";
import type { TagShotHighlight, TaggedPlayer } from "../../shared/types";
import HighlightCard from "./HighlightCard";

type InputProps = {
  dataset: TagShotHighlight[];
  players: TaggedPlayer[];
}

/**
 * A component that displays a list of highlights
 * @param dataset (TagShotHighlight[])
 * @param players (TaggedPlayer[])
 */
const Highlights = ({ dataset, players }: InputProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        overflowX: "auto",
        gap: 2,
        "&::-webkit-scrollbar": { height: 8 },
        "&::-webkit-scrollbar-thumb": { backgroundColor: "#ccc", borderRadius: 4 },
      }}
    >
      {dataset.map((item, index) => {
        const player = players.find(player => player.clusterId === item.tag.clusterId);
        return (
          <Box key={`highlight-box-${index}`} sx={{ flexShrink: 0 }}>
            <HighlightCard item={item} player={player} />
          </Box>
        );
      })}
    </Box>
  );
};

export default Highlights;
