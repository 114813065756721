import React from "react";
import { Box, Typography } from "@mui/material";
import type { ShotChartZones } from "../../../shared/types";

type InputProps = {
  shotChartZones: ShotChartZones,
}

/**
 * ShotChartMap - Render shot chart on profile page
 * @param shotChartZones ShotChartZones
 */
const ShotChartZoneMap = ({ shotChartZones }: InputProps) => {

  const getZoneColor = (percentageString: string, attempts: number) => {
    // Lightest Green #1CBE4940 100%
    // Light Green #1CBE4940 75%
    // Middle Green #1CBE4940 50%
    // Dark Green: #1CBE4940 33%
    // Darkest Green: #1CBE4940 25%
    // Red: #E52E4D33 20%

    // Lightest Green: 76-100
    // Light Green: 50-75
    // Middle Green: 34-49
    // Dark Green: 25-33 **NOT GOING TO DO**
    // Darkest Green: 10-24 **NOT GOING TO DO**
    // Red: 0-9

    if (attempts === 0) return {color: "#202429", opacity: 1};

    const percentage = Number(percentageString.replace("%", ""));
    if (percentage < 10) {
      return {color: "#E52E4D", opacity: 0.7};
    } else if (percentage >= 10 && percentage < 34) {
      return {color: "#E52E4D", opacity: 0.3};
    } else if (percentage >= 34 && percentage < 50) {
      return {color: "#1CBE49", opacity: 0.3};
    } else if (percentage >= 50 && percentage <= 75) {
      return {color: "#1CBE49", opacity: 0.7};
    } else if (percentage > 75) {
      return {color: "#1CBE49", opacity: 1};
    } else {
      return {color: "#202429", opacity: 1};
    }
  }

  const getZoneLabels = () => {
    // canvas: 313x223
    return (
      <Box sx={{ position: 'absolute', width: '100%', height: '100%' }}>
        {/* blockLeft */}
        <Box sx={{ position: "absolute", width: 40, height: 28, top: 16, left: "19%" }}>
          <Typography sx={{ fontSize: 14, fontWeight: "bold", textAlign: "center", color: 'white' }}>
            {shotChartZones.BLOCK_LEFT.attempts > 0 ? `${shotChartZones.BLOCK_LEFT.percentage}`: "0%"}
          </Typography>
          <Typography sx={{ fontSize: 12, textAlign: "center", color: 'white' }}>
            {shotChartZones.BLOCK_LEFT.attempts > 0 ? `${shotChartZones.BLOCK_LEFT.makes}/${shotChartZones.BLOCK_LEFT.attempts}`: "0/0"}
          </Typography>
        </Box>
        {/* blockRight */}
        <Box sx={{ position: "absolute", width: 40, height: 28, top: 16, left: "69%" }}>
          <Typography sx={{ fontSize: 14, fontWeight: "bold", textAlign: "center", color: 'white' }}>
            {shotChartZones.BLOCK_RIGHT.attempts > 0 ? `${shotChartZones.BLOCK_RIGHT.percentage}`: "0%"}
          </Typography>
          <Typography sx={{ fontSize: 12, textAlign: "center", color: 'white' }}>
            {shotChartZones.BLOCK_RIGHT.attempts > 0 ? `${shotChartZones.BLOCK_RIGHT.makes}/${shotChartZones.BLOCK_RIGHT.attempts}`: "0/0"}
          </Typography>
        </Box>
        {/* paintUpperLeft */}
        <Box sx={{ position: "absolute", width: 40, height: 28, top: 16, left: "37%" }}>
          <Typography sx={{ fontSize: 14, fontWeight: "bold", textAlign: "center", color: 'white' }}>
            {shotChartZones.PAINT_UPPER_LEFT.attempts > 0 ? `${shotChartZones.PAINT_UPPER_LEFT.percentage}`: "0%"}
          </Typography>
          <Typography sx={{ fontSize: 12, textAlign: "center", color: 'white' }}>
            {shotChartZones.PAINT_UPPER_LEFT.attempts > 0 ? `${shotChartZones.PAINT_UPPER_LEFT.makes}/${shotChartZones.PAINT_UPPER_LEFT.attempts}`: "0/0"}
          </Typography>
        </Box>
        {/* paintUpperRight */}
        <Box sx={{ position: "absolute", width: 40, height: 28, top: 16, left: "51%" }}>
          <Typography sx={{ fontSize: 14, fontWeight: "bold", textAlign: "center", color: 'white' }}>
            {shotChartZones.PAINT_UPPER_RIGHT.attempts > 0 ? `${shotChartZones.PAINT_UPPER_RIGHT.percentage}`: "0%"}
          </Typography>
          <Typography sx={{ fontSize: 12, textAlign: "center", color: 'white' }}>
            {shotChartZones.PAINT_UPPER_RIGHT.attempts > 0 ? `${shotChartZones.PAINT_UPPER_RIGHT.makes}/${shotChartZones.PAINT_UPPER_RIGHT.attempts}`: "0/0"}
          </Typography>
        </Box>
        {/* paintBottomLeft */}
        <Box sx={{ position: "absolute", width: 40, height: 28, top: 80, left: "37%" }}>
          <Typography sx={{ fontSize: 14, fontWeight: "bold", textAlign: "center", color: 'white' }}>
            {shotChartZones.PAINT_BOTTOM_LEFT.attempts > 0 ? `${shotChartZones.PAINT_BOTTOM_LEFT.percentage}`: "0%"}
          </Typography>
          <Typography sx={{ fontSize: 12, textAlign: "center", color: 'white' }}>
            {shotChartZones.PAINT_BOTTOM_LEFT.attempts > 0 ? `${shotChartZones.PAINT_BOTTOM_LEFT.makes}/${shotChartZones.PAINT_BOTTOM_LEFT.attempts}`: "0/0"}
          </Typography>
        </Box>
        {/* paintBottomRight */}
        <Box sx={{ position: "absolute", width: 40, height: 28, top: 80, left: "51%" }}>
          <Typography sx={{ fontSize: 14, fontWeight: "bold", textAlign: "center", color: 'white' }}>
            {shotChartZones.PAINT_BOTTOM_RIGHT.attempts > 0 ? `${shotChartZones.PAINT_BOTTOM_RIGHT.percentage}`: "0%"}
          </Typography>
          <Typography sx={{ fontSize: 12, textAlign: "center", color: 'white' }}>
            {shotChartZones.PAINT_BOTTOM_RIGHT.attempts > 0 ? `${shotChartZones.PAINT_BOTTOM_RIGHT.makes}/${shotChartZones.PAINT_BOTTOM_RIGHT.attempts}`: "0/0"}
          </Typography>
        </Box>
        {/* keyArea */}
        <Box sx={{ position: "absolute", width: 40, height: 28, top: 136, left: "44%" }}>
          <Typography sx={{ fontSize: 14, fontWeight: "bold", textAlign: "center", color: 'white' }}>
            {shotChartZones.KEY_ZONE.attempts > 0 ? `${shotChartZones.KEY_ZONE.percentage}`: "0%"}
          </Typography>
          <Typography sx={{ fontSize: 12, textAlign: "center", color: 'white' }}>
            {shotChartZones.KEY_ZONE.attempts > 0 ? `${shotChartZones.KEY_ZONE.makes}/${shotChartZones.KEY_ZONE.attempts}`: "0/0"}
          </Typography>
        </Box>
        {/* elbowLeft */}
        <Box sx={{ position: "absolute", width: 40, height: 28, top: 86, left: "21%" }}>
          <Typography sx={{ fontSize: 14, fontWeight: "bold", textAlign: "center", color: 'white' }}>
            {shotChartZones.ELBOW_LEFT.attempts > 0 ? `${shotChartZones.ELBOW_LEFT.percentage}`: "0%"}
          </Typography>
          <Typography sx={{ fontSize: 12, textAlign: "center", color: 'white' }}>
            {shotChartZones.ELBOW_LEFT.attempts > 0 ? `${shotChartZones.ELBOW_LEFT.makes}/${shotChartZones.ELBOW_LEFT.attempts}`: "0/0"}
          </Typography>
        </Box>
        {/* elbowRight */}
        <Box sx={{ position: "absolute", width: 40, height: 28, top: 86, left: "68%" }}>
          <Typography sx={{ fontSize: 14, fontWeight: "bold", textAlign: "center", color: 'white' }}>
            {shotChartZones.ELBOW_RIGHT.attempts > 0 ? `${shotChartZones.ELBOW_RIGHT.percentage}`: "0%"}
          </Typography>
          <Typography sx={{ fontSize: 12, textAlign: "center", color: 'white' }}>
            {shotChartZones.ELBOW_RIGHT.attempts > 0 ? `${shotChartZones.ELBOW_RIGHT.makes}/${shotChartZones.ELBOW_RIGHT.attempts}`: "0/0"}
          </Typography>
        </Box>
        {/* cornerLeft */}
        <Box sx={{ position: "absolute", width: 40, height: 28, top: 40, left: "4%" }}>
          <Typography sx={{ fontSize: 14, fontWeight: "bold", textAlign: "center", color: 'white' }}>
            {shotChartZones.CORNER_LEFT.attempts > 0 ? `${shotChartZones.CORNER_LEFT.percentage}`: "0%"}
          </Typography>
          <Typography sx={{ fontSize: 12, textAlign: "center", color: 'white' }}>
            {shotChartZones.CORNER_LEFT.attempts > 0 ? `${shotChartZones.CORNER_LEFT.makes}/${shotChartZones.CORNER_LEFT.attempts}`: "0/0"}
          </Typography>
        </Box>
        {/* cornerRight */}
        <Box sx={{ position: "absolute", width: 40, height: 28, top: 40, left: "84%" }}>
          <Typography sx={{ fontSize: 14, fontWeight: "bold", textAlign: "center", color: 'white' }}>
            {shotChartZones.CORNER_RIGHT.attempts > 0 ? `${shotChartZones.CORNER_RIGHT.percentage}`: "0%"}
          </Typography>
          <Typography sx={{ fontSize: 12, textAlign: "center", color: 'white' }}>
            {shotChartZones.CORNER_RIGHT.attempts > 0 ? `${shotChartZones.CORNER_RIGHT.makes}/${shotChartZones.CORNER_RIGHT.attempts}`: "0/0"}
          </Typography>
        </Box>
        {/* wingLeft */}
        <Box sx={{ position: "absolute", width: 40, height: 28, top: 152, left: "12%" }}>
          <Typography sx={{ fontSize: 14, fontWeight: "bold", textAlign: "center", color: 'white' }}>
            {shotChartZones.WING_LEFT.attempts > 0 ? `${shotChartZones.WING_LEFT.percentage}`: "0%"}
          </Typography>
          <Typography sx={{ fontSize: 12, textAlign: "center", color: 'white' }}>
            {shotChartZones.WING_LEFT.attempts > 0 ? `${shotChartZones.WING_LEFT.makes}/${shotChartZones.WING_LEFT.attempts}`: "0/0"}
          </Typography>
        </Box>
        {/* wingRight */}
        <Box sx={{ position: "absolute", width: 40, height: 28, top: 152, left: "75%" }}>
          <Typography sx={{ fontSize: 14, fontWeight: "bold", textAlign: "center", color: 'white' }}>
            {shotChartZones.WING_RIGHT.attempts > 0 ? `${shotChartZones.WING_RIGHT.percentage}`: "0%"}
          </Typography>
          <Typography sx={{ fontSize: 12, textAlign: "center", color: 'white' }}>
            {shotChartZones.WING_RIGHT.attempts > 0 ? `${shotChartZones.WING_RIGHT.makes}/${shotChartZones.WING_RIGHT.attempts}`: "0/0"}
          </Typography>
        </Box>
        {/* middleThreePoint */}
        <Box sx={{ position: "absolute", width: 40, height: 28, top: 182, left: "44%" }}>
          <Typography sx={{ fontSize: 14, fontWeight: "bold", textAlign: "center", color: 'white' }}>
            {shotChartZones.MIDDLE_THREE_POINT.attempts > 0 ? `${shotChartZones.MIDDLE_THREE_POINT.percentage}`: "0%"}
          </Typography>
          <Typography sx={{ fontSize: 12, textAlign: "center", color: 'white' }}>
            {shotChartZones.MIDDLE_THREE_POINT.attempts > 0 ? `${shotChartZones.MIDDLE_THREE_POINT.makes}/${shotChartZones.MIDDLE_THREE_POINT.attempts}`: "0/0"}
          </Typography>
        </Box>
      </Box>
    )
  }
  return (
    <Box style={{ position: 'relative', alignItems: "center", alignContent:'center' }}>
      {getZoneLabels()}
      <svg
        width={313}
        height={223}
        viewBox="0 0 313 223"
        fill="none"
      >
        <path
          stroke="#78808D"
          strokeWidth={2}
          strokeMiterlimit={10}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M1 2H311V222H1z"
        />
        <path
          d="M156.004 181.547C222.289 181.547 276 127.8 276 61.552V1.549H36v60.003c.009 66.248 53.72 119.995 120.004 119.995z"
          stroke="#78808D"
          strokeWidth={2}
          strokeMiterlimit={10}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        {/* blockRight */}
        <path
          d="M276 66.547h-70v-65h70v65z"
          fill={getZoneColor(shotChartZones.BLOCK_RIGHT.percentage, shotChartZones.BLOCK_RIGHT.attempts).color}
          fillOpacity={getZoneColor(shotChartZones.BLOCK_RIGHT.percentage, shotChartZones.BLOCK_RIGHT.attempts).opacity}
          stroke="#78808D"
          strokeWidth={2}
          strokeMiterlimit={10}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        {/* paintUpperRight */}
        <path
          d="M156 66.547h50v-65h-50v65z"
          fill={getZoneColor(shotChartZones.PAINT_UPPER_RIGHT.percentage, shotChartZones.PAINT_UPPER_RIGHT.attempts).color}
          fillOpacity={getZoneColor(shotChartZones.PAINT_UPPER_RIGHT.percentage, shotChartZones.PAINT_UPPER_RIGHT.attempts).opacity}
          stroke="#78808D"
          strokeWidth={2}
          strokeMiterlimit={10}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        {/* paintUpperLeft */}
        <path
          d="M106 66.547h50v-65h-50v65z"
          fill={getZoneColor(shotChartZones.PAINT_UPPER_LEFT.percentage, shotChartZones.PAINT_UPPER_LEFT.attempts).color}
          fillOpacity={getZoneColor(shotChartZones.PAINT_UPPER_LEFT.percentage, shotChartZones.PAINT_UPPER_LEFT.attempts).opacity}
          stroke="#78808D"
          strokeWidth={2}
          strokeMiterlimit={10}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        {/* blockLeft */}
        <path
          d="M106 66.547H36v-65h70v65z"
          fill={getZoneColor(shotChartZones.BLOCK_LEFT.percentage, shotChartZones.BLOCK_LEFT.attempts).color}
          fillOpacity={getZoneColor(shotChartZones.BLOCK_LEFT.percentage, shotChartZones.BLOCK_LEFT.attempts).opacity}
          stroke="#78808D"
          strokeWidth={2}
          strokeMiterlimit={10}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        {/* wingRight */}
        <path
          d="M206 170.855c27.797-12.761 49.876-35.832 61.349-64.308H311v115H206v-50.692z"
          fill={getZoneColor(shotChartZones.WING_RIGHT.percentage, shotChartZones.WING_RIGHT.attempts).color}
          fillOpacity={getZoneColor(shotChartZones.WING_RIGHT.percentage, shotChartZones.WING_RIGHT.attempts).opacity}
          stroke="#78808D"
          strokeWidth={2}
          strokeMiterlimit={10}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        {/* middleThreePoint */}
        <path
          d="M106 170.662c15.221 6.988 32.157 10.885 50.005 10.885 17.843 0 34.776-3.895 49.995-10.881v50.881H106v-50.885z"
          fill={getZoneColor(shotChartZones.MIDDLE_THREE_POINT.percentage, shotChartZones.MIDDLE_THREE_POINT.attempts).color}
          fillOpacity={getZoneColor(shotChartZones.MIDDLE_THREE_POINT.percentage, shotChartZones.MIDDLE_THREE_POINT.attempts).opacity}
          stroke="#78808D"
          strokeWidth={2}
          strokeMiterlimit={10}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        {/* wingLeft */}
        <path
          d="M106 170.855c-27.796-12.761-49.876-35.832-61.349-64.308H1v115h105v-50.692z"
          fill={getZoneColor(shotChartZones.WING_LEFT.percentage, shotChartZones.WING_LEFT.attempts).color}
          fillOpacity={getZoneColor(shotChartZones.WING_LEFT.percentage, shotChartZones.WING_LEFT.attempts).opacity}
          stroke="#78808D"
          strokeWidth={2}
          strokeMiterlimit={10}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        {/* elbowRight */}
        <path
          d="M206 170.547c39.883-18.297 68.016-57.773 70-104h-70v104z"
          fill={getZoneColor(shotChartZones.ELBOW_RIGHT.percentage, shotChartZones.ELBOW_RIGHT.attempts).color}
          fillOpacity={getZoneColor(shotChartZones.ELBOW_RIGHT.percentage, shotChartZones.ELBOW_RIGHT.attempts).opacity}
          stroke="#78808D"
          strokeWidth={2}
          strokeMiterlimit={10}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        {/* paintBottomRight */}
        <path
          d="M156 131.547h50v-65h-50v65z"
          fill={getZoneColor(shotChartZones.PAINT_BOTTOM_RIGHT.percentage, shotChartZones.PAINT_BOTTOM_RIGHT.attempts).color}
          fillOpacity={getZoneColor(shotChartZones.PAINT_BOTTOM_RIGHT.percentage, shotChartZones.PAINT_BOTTOM_RIGHT.attempts).opacity}
          stroke="#78808D"
          strokeWidth={2}
          strokeMiterlimit={10}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        {/* paintBottomLeft */}
        <path
          d="M106 131.547h50v-65h-50v65z"
          fill={getZoneColor(shotChartZones.PAINT_BOTTOM_LEFT.percentage, shotChartZones.PAINT_BOTTOM_LEFT.attempts).color}
          fillOpacity={getZoneColor(shotChartZones.PAINT_BOTTOM_LEFT.percentage, shotChartZones.PAINT_BOTTOM_LEFT.attempts).opacity}
          stroke="#78808D"
          strokeWidth={2}
          strokeMiterlimit={10}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        {/* elbowLeft */}
        <path
          d="M106 170.547c-39.883-18.297-68.016-57.773-70-104h70v104z"
          fill={getZoneColor(shotChartZones.ELBOW_LEFT.percentage, shotChartZones.ELBOW_LEFT.attempts).color}
          fillOpacity={getZoneColor(shotChartZones.ELBOW_LEFT.percentage, shotChartZones.ELBOW_LEFT.attempts).opacity}
          stroke="#78808D"
          strokeWidth={2}
          strokeMiterlimit={10}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        {/* cornerLeft */}
        <path
          d="M1 1.547v105h43.651c-.513-1.425-1.041-2.843-1.555-4.267-4.59-12.718-7.094-26.432-7.096-40.729V1.55L1 1.547z"
          fill={getZoneColor(shotChartZones.CORNER_LEFT.percentage, shotChartZones.CORNER_LEFT.attempts).color}
          fillOpacity={getZoneColor(shotChartZones.CORNER_LEFT.percentage, shotChartZones.CORNER_LEFT.attempts).opacity}
          stroke="#78808D"
          strokeWidth={2}
          strokeMiterlimit={10}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        {/* cornerRight */}
        <path
          d="M310.651 1.547v105H267c.513-1.425 1.042-2.843 1.556-4.267 4.59-12.718 7.093-26.432 7.095-40.729V1.55l35-.002z"
          fill={getZoneColor(shotChartZones.CORNER_RIGHT.percentage, shotChartZones.CORNER_RIGHT.attempts).color}
          fillOpacity={getZoneColor(shotChartZones.CORNER_RIGHT.percentage, shotChartZones.CORNER_RIGHT.attempts).opacity}
          stroke="#78808D"
          strokeWidth={2}
          strokeMiterlimit={10}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        {/* keyArea */}
        <path
          d="M206 131.547H106v39.115c15.221 6.988 32.157 10.885 50.004 10.885 17.844 0 34.777-3.895 49.996-10.881v-39.119z"
          fill={getZoneColor(shotChartZones.KEY_ZONE.percentage, shotChartZones.KEY_ZONE.attempts).color}
          fillOpacity={getZoneColor(shotChartZones.KEY_ZONE.percentage, shotChartZones.KEY_ZONE.attempts).opacity}
          stroke="#78808D"
          strokeWidth={2}
          strokeMiterlimit={10}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Box>
  )
}

export default ShotChartZoneMap;
