import React, { useState, useEffect } from "react";
import ShotChartGrid from "./shotcharts/ShotChartGrid";
import { 
  SHOOTAROUND_MODE, 
  SESSION_SOLO, 
  SHOT_CHART_WIDTH, 
  SHOT_CHART_HEIGHT, 
  SHOT_STATUS_MAKE, 
  SHOT_STATUS_MISS 
} from "../../shared/constants";
import ToggleCluster from "./shotcharts/ToggleCluster";
import ToggleTeam from "./shotcharts/ToggleTeam";
import type { FBSession, ClusterActionsV4, FBClusterTagV4, PlayerShot } from "../../shared/types";
import { getClusterIdsByTeamV4, isShotIn, isShotTwoPointer } from "../../shared/utils";
import { Box } from "@mui/material";

type InputProps = {
  session: FBSession,
  clusterTags: FBClusterTagV4[],
  clusterShots: ClusterActionsV4[],
}

const ShotChart = ({ session, clusterTags, clusterShots }: InputProps) => {
  // Toggle between users in a workout
  const [selectedClusterId, setSelectedClusterId] = useState<string>();
  // 1 -> team 1, 2 -> team 2
  const [selectedTeamId, setSelectedTeamId] = useState<number>(1);

  useEffect(() => {
    if (clusterTags.length > 0) {
      setSelectedClusterId(clusterTags[0].clusterId);
    }
  }, [clusterTags, session.sessionId]);

  /**
   * Get toggle to switch between shot charts
   */
  const getToggle = (mode: number) => {
    if (session.sessionType === SESSION_SOLO || session.numPlayers === 1) {
      return null;
    } else if (mode === 0) {
      if (!selectedClusterId) return null;
      return (
        <ToggleCluster 
          clusters={clusterTags}
          selectedClusterId={selectedClusterId}
          setSelectedClusterId={setSelectedClusterId}
        />
      );
    } else {
      return (
        <ToggleTeam
          teamNames={session.teamNames || ["Team 1", "Team 2"]}
          selectedTeamId={selectedTeamId}
          setSelectedTeamId={setSelectedTeamId}
        />
      );
    }
  }

  /**
   * Get the shot chart
   * @returns 
   */
  const getShotChart = (mode: number) => {
    var filteredShots: ClusterActionsV4[] = [];
    if (mode === 0) {
      filteredShots = clusterShots.filter(shot => shot.clusterId === selectedClusterId);
    } else {
      const [team1Ids, team2Ids] = getClusterIdsByTeamV4(clusterTags);
      var i: number;
      var j: number;
      if (selectedTeamId === 1) { 
        filteredShots = clusterShots.filter(shot => team1Ids.includes(shot.clusterId));
      } else {
        filteredShots = clusterShots.filter(shot => team2Ids.includes(shot.clusterId));
      }
    }  
    // Normalize shots in minimap
    var shotsToDisplay: PlayerShot[] = [];
    for (i = 0; i < filteredShots.length; i++) {
      for (j = 0; j < filteredShots[i].shots.length; j++) {
        let shot = filteredShots[i].shots[j];
        if (!shot.scorerLocation) continue;
        if (shot.ignore) continue;
        const location = [shot.scorerLocation[0] / SHOT_CHART_WIDTH, shot.scorerLocation[1] / SHOT_CHART_HEIGHT];
        const shotToDisplay: PlayerShot = {
          location: location,
          status: isShotIn(shot) ? SHOT_STATUS_MAKE : SHOT_STATUS_MISS,
          ignore: shot.ignore,
          isTwoPointShot: isShotTwoPointer(shot),
        }
        shotsToDisplay.push(shotToDisplay);
      }
    }
    return <ShotChartGrid playerShots={shotsToDisplay} />;
  }

  return (
    <Box>
      <Box>
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4, mb: 6 }}>
          {getToggle(0)}
        </Box>
        {getShotChart(0)}
      </Box>
      {!(session.sessionMode === SHOOTAROUND_MODE || !session.hasTeams || session.sessionType === SESSION_SOLO) && (
        <Box>
          <Box sx={{ display: "flex", justifyContent: "center", mt: 6, mb: 6 }}>
            {getToggle(1)}
          </Box>
          {getShotChart(1)}
        </Box>
      )}
    </Box>
  );
}

export default ShotChart;
